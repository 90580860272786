/**
 * @description Ищет подстроку в искомой строке
 * @param payload
 * @param search
 */
var stringSearch = /* #__PURE__ */ function (payload, search) {
    if (!payload)
        return true;
    var regExpClearString = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Экранируем все специальные символы
    var regex = new RegExp(regExpClearString, 'i');
    return regex.test(payload);
};
export { stringSearch };
