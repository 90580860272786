import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { classNames } from 'shared/lib/classNames/classNames';
import { AppLink, AppLinkTheme } from 'shared/ui/AppLink/AppLink';
import Hat from 'shared/assets/icons/school-hat.svg';
import Notebook from 'shared/assets/icons/notebook.svg';
import Checklist from 'shared/assets/icons/checklist.svg';
import Calendar from 'shared/assets/icons/calendar.svg';
import Cup from 'shared/assets/icons/cup.svg';
import Clipboard from 'shared/assets/icons/clipboard-heart.svg';
import Notes from 'shared/assets/icons/notes.svg';
import Groups from 'shared/assets/icons/groups.svg';
import Users from 'shared/assets/icons/users.svg';
import Graph from 'shared/assets/icons/graph.svg';
import Flag from 'shared/assets/icons/flag.svg';
import Folder from 'shared/assets/icons/folder-open.svg';
import { getUserRoles } from 'entities/User/model/selectors/getUser';
import cls from './Navigation.module.scss';
export var Navigation = function (props) {
    var className = props.className;
    var userRoles = useSelector(getUserRoles);
    return (_jsx("div", { className: classNames(cls.Navigation, {}, [className]), children: _jsxs("ul", { className: cls.list, children: [_jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/lessons", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Hat, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u041A\u0443\u0440\u0441\u044B" }) }) }), _jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/calendar", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Calendar, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u041A\u0430\u043B\u0435\u043D\u0434\u0430\u0440\u044C" }) }) }), _jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/tests", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Checklist, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u0422\u0435\u0441\u0442\u044B" }) }) }), _jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/achievements", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Cup, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u041C\u043E\u0438 \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F" }) }) }), ((userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('SuperAdministrator')) || (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('Administrator')))
                    && (_jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/tasks", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Notebook, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u0417\u0430\u0434\u0430\u043D\u0438\u044F" }) }) })), _jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/evaluations", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Clipboard, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u041C\u043E\u0438 \u043E\u0446\u0435\u043D\u043A\u0438" }) }) }), _jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/voting", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Notes, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u041C\u043E\u0438 \u0433\u043E\u043B\u043E\u0441\u043E\u0432\u0430\u043D\u0438\u044F" }) }) }), ((userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('SuperAdministrator')) || (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('Administrator')))
                    && (_jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/statistic", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Graph, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u043E\u0431\u0443\u0447\u0435\u043D\u0438\u044F" }) }) })), _jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/track", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Flag, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u041E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0439 \u0442\u0440\u0435\u043A" }) }) }), _jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/base", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Folder, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u0411\u0430\u0437\u0430 \u0437\u043D\u0430\u043D\u0438\u0439" }) }) }), ((userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('SuperAdministrator')) || (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('Administrator')))
                    && (_jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/users", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Users, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438" }) }) })), ((userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('SuperAdministrator')) || (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('Administrator')))
                    && (_jsx("li", { className: cls.item, children: _jsx(AppLink, { className: cls.blockItem, to: "/groups", theme: AppLinkTheme.NAVIGATION, icon: _jsx(Groups, {}), children: _jsx("span", { className: cls.textDesktop, children: "\u0413\u0440\u0443\u043F\u043F\u044B" }) }) }))] }) }));
};
