import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isLoading: false,
    isModalOpen: false,
};
export var chatSlice = createSlice({
    name: 'chat',
    initialState: initialState,
    reducers: {
        toggleChatModal: function (state) {
            state.isModalOpen = !state.isModalOpen;
        },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(getCoursesLessons.pending, (state) => {
    //             state.error = undefined;
    //             state.isLoading = true;
    //         })
    //         .addCase(getCoursesLessons.fulfilled, (state, action) => {
    //             state.isLoading = false;
    //             state.coursesList = action.payload;
    //         })
    //         .addCase(getCoursesLessons.rejected, (state, action) => {
    //             state.isLoading = false;
    //             state.error = action.payload;
    //         });
    // },
});
export var chatActions = chatSlice.actions;
export var chatReducer = chatSlice.reducer;
