import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Tab } from 'shared/ui/Tab';
import { BlockLoader } from 'shared/ui/BlockLoader';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'shared/lib/classNames/classNames';
import { getCourse, getCoursesLessons } from 'entities/Course';
import { Link } from 'react-router-dom';
import cls from './CourseWidget.module.scss';
export var CourseWidget = function (props) {
    var className = props.className;
    var dispatch = useDispatch();
    var courses = useSelector(getCourse);
    useEffect(function () {
        dispatch(getCoursesLessons({}));
    }, []);
    var getCoursesList = function (param) {
        var _a, _b;
        if ((_a = courses === null || courses === void 0 ? void 0 : courses.coursesList) === null || _a === void 0 ? void 0 : _a.coursesLessons) {
            var coursesData = Object.values((_b = courses === null || courses === void 0 ? void 0 : courses.coursesList) === null || _b === void 0 ? void 0 : _b.coursesLessons);
            if (param === 'start') {
                coursesData = coursesData.filter(function (item) { return item.progress > 0 && item.progress !== 100; });
            }
            if (param === 'finished') {
                coursesData = coursesData.filter(function (item) { return item.progress === 100; });
            }
            if (coursesData.length === 0)
                return null;
            return (_jsx("div", { className: cls.list, children: coursesData === null || coursesData === void 0 ? void 0 : coursesData.slice(0, 3).map(function (item) { return (_jsxs(Link, { to: "/lessons/".concat(item.id), className: cls.item, children: [_jsx("div", { className: cls.name, children: item.name }), _jsxs("div", { className: cls.percent, children: ["\u041F\u0440\u043E\u0439\u0434\u0435\u043D \u043D\u0430 ", item.progress, " %"] })] }, item.id)); }) }));
        }
        return null;
    };
    var tabData = [
        {
            label: 'Все курсы',
            itemContent: getCoursesList() || _jsx("div", { className: cls.empty, children: "\u041D\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0445 \u043A\u0443\u0440\u0441\u043E\u0432" }),
        },
        {
            label: 'Курс начат',
            itemContent: getCoursesList('start') || _jsx("div", { className: cls.empty, children: "\u041D\u0435\u0442 \u043D\u0430\u0447\u0430\u0442\u044B\u0445 \u043A\u0443\u0440\u0441\u043E\u0432" }),
        },
        {
            label: 'Завершенные курсы',
            itemContent: getCoursesList('finished') || _jsx("div", { className: cls.empty, children: "\u041D\u0435\u0442 \u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u043D\u044B\u0445 \u043A\u0443\u0440\u0441\u043E\u0432" }),
        },
    ];
    return (_jsx("div", { className: classNames(cls.CourseWidget, {}, [className]), children: courses.isLoading
            ? _jsx(BlockLoader, {})
            : _jsx(Tab, { classNameList: cls.tabList, items: tabData }) }));
};
