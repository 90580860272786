import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import { Button } from 'shared/ui/Button/Button';
import ModalClose from 'shared/assets/icons/input-close.svg';
import cls from './Modal.module.scss';
export var ModalTheme;
(function (ModalTheme) {
    ModalTheme["ON_SIDE"] = "onSide";
})(ModalTheme || (ModalTheme = {}));
export var Modal = function (props) {
    var _a;
    var className = props.className, classNameContent = props.classNameContent, children = props.children, _b = props.theme, theme = _b === void 0 ? '' : _b, isOpen = props.isOpen, onClose = props.onClose;
    var mods = (_a = {}, _a[cls.opened] = isOpen, _a);
    var closeHandler = function () {
        if (onClose) {
            onClose();
        }
    };
    var onContentClick = function (e) {
        e.stopPropagation();
    };
    return (_jsx("div", { className: classNames(cls.Modal, mods, [className, cls[theme]]), children: _jsx("div", { className: cls.container, onClick: closeHandler, children: _jsxs("div", { className: classNames(cls.content, {}, [classNameContent]), onClick: onContentClick, children: [_jsx(Button, { className: cls.close, onClick: closeHandler, children: _jsx(ModalClose, { className: cls.closeIcon }) }), children] }) }) }));
};
