import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from 'entities/User/model/services/getAccessToken';
var BplAuth = function () {
    var location = useLocation();
    var queryParams = new URLSearchParams(location.search);
    var dispatch = useDispatch();
    var code = queryParams.get('code');
    if (!code) {
        window.location.href = 'e/404';
        return;
    }
    dispatch(updateUser({ code: code }));
    return (_jsx(_Fragment, {}));
};
export default BplAuth;
