import { createSlice } from '@reduxjs/toolkit';
import { addProjectToCourse } from 'entities/Project/model/services/addProjectToCourse';
import { fetchProjectsList } from '../services/fetchProjectsList';
var initialState = {
    isLoading: false,
    isAddProjectLoading: false,
    isModalOpen: false,
};
export var projectSlice = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        toggleProjectModal: function (state) {
            state.isModalOpen = !state.isModalOpen;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(fetchProjectsList.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchProjectsList.fulfilled, function (state, action) {
            state.isLoading = false;
            state.projectList = action.payload.events;
        })
            .addCase(fetchProjectsList.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(addProjectToCourse.pending, function (state) {
            state.error = undefined;
            state.isAddProjectLoading = true;
        })
            .addCase(addProjectToCourse.fulfilled, function (state) {
            state.isAddProjectLoading = false;
            // state.projectList = action.payload.project;
        })
            .addCase(addProjectToCourse.rejected, function (state, action) {
            state.isAddProjectLoading = false;
            state.error = action.payload;
        });
    },
});
export var projectActions = projectSlice.actions;
export var projectReducer = projectSlice.reducer;
