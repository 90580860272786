import { createSlice } from '@reduxjs/toolkit';
import { getMessageById } from '../services/getMessageById';
import { getTitles } from '../services/getTitles';
import { sendMessage } from '../services/sendMessage';
import { closeMessage } from '../services/closeMessage';
var initialState = {
    isLoading: false,
    formStatus: false,
    error: undefined,
    messageData: undefined,
    titles: [],
};
export var supportSlice = createSlice({
    name: 'support',
    initialState: initialState,
    reducers: {
        toggleSupportForm: function (state) {
            state.formStatus = !state.formStatus;
        },
        getMessage: function (state, payload) {
            console.log(state, payload);
        },
        addMessage: function (state, action) {
            var _a;
            console.log(state, action);
            (_a = state.messageData) === null || _a === void 0 ? void 0 : _a.messages.unshift(action.payload);
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getMessageById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getMessageById.fulfilled, function (state, action) {
            state.messageData = action.payload.data;
            state.isLoading = false;
        })
            .addCase(getMessageById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getTitles.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getTitles.fulfilled, function (state, action) {
            state.isLoading = false;
            state.titles = action.payload.data;
        })
            .addCase(getTitles.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(sendMessage.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(sendMessage.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(sendMessage.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(closeMessage.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(closeMessage.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(closeMessage.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var supportActions = supportSlice.actions;
export var supportReducer = supportSlice.reducer;
