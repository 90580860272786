import { createSlice } from '@reduxjs/toolkit';
import { getChatMessages } from 'entities/Team/model/services/getChatMessages';
import { sendMessage } from 'entities/Support/model/services/sendMessage';
import { createChat } from '../services/createChat';
import { getUserRooms } from '../services/getUserRooms';
var initialState = {
    isLoading: false,
    isSendMessage: false,
    error: undefined,
    messagesData: undefined,
    rooms: [],
};
export var teamSlice = createSlice({
    name: 'team',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getChatMessages.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getChatMessages.fulfilled, function (state, action) {
            state.messagesData = action.payload;
            state.isLoading = false;
        })
            .addCase(getChatMessages.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(createChat.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(createChat.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(createChat.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getUserRooms.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getUserRooms.fulfilled, function (state, action) {
            state.rooms = action.payload.data;
            state.isLoading = false;
        })
            .addCase(getUserRooms.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(sendMessage.pending, function (state) {
            state.error = undefined;
            state.isSendMessage = true;
        })
            .addCase(sendMessage.fulfilled, function (state) {
            state.isSendMessage = false;
        })
            .addCase(sendMessage.rejected, function (state, action) {
            state.isSendMessage = false;
            state.error = action.payload;
        });
    },
});
export var teamActions = teamSlice.actions;
export var teamReducer = teamSlice.reducer;
