var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormProvider, useForm, } from 'react-hook-form';
import { classNames } from 'shared/lib/classNames';
import cls from './Form.module.scss';
export function Form(_a) {
    var title = _a.title, children = _a.children, onSubmit = _a.onSubmit, options = _a.options, submitButtonSlot = _a.submitButtonSlot, className = _a.className;
    var formMethods = useForm(__assign(__assign({ reValidateMode: 'onSubmit' }, options), { shouldFocusError: false }));
    var handleSubmit = formMethods.handleSubmit, setError = formMethods.setError, _b = formMethods.formState, errors = _b.errors, isSubmitting = _b.isSubmitting, watch = formMethods.watch, clearErrors = formMethods.clearErrors;
    watch(function (data, event) {
        var error = errors[event.name]; // TODO make type
        if (typeof error === 'undefined' || event.type !== 'change')
            return;
        clearErrors(event.name);
    });
    return (_jsx(FormProvider, __assign({}, formMethods, { children: _jsxs("form", { className: classNames(cls.Form, {}, [className]), onSubmit: handleSubmit(function (state) { return onSubmit(state, setError); }), children: [typeof title !== 'undefined' && _jsx("h1", { className: cls.FormTitle, children: title }), _jsx("div", { className: cls.FormElements, children: children }), submitButtonSlot && submitButtonSlot] }) })));
}
