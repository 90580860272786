export var getUser = function (state) { return state.user; };
export var getUserIsLoading = function (state) { return state.user.isLoading; };
export var getUserIsAddLoading = function (state) { return state.user.isAddUserLoading; };
export var getUserIsActiveUserLoading = function (state) { return state.user.isActiveUserLoading; };
export var getUserModalStatus = function (state) { return state.user.userModalStatus; };
export var getUserAuthData = function (state) { return state.user.authData; };
export var getUsersList = function (state) { return state.user.userList; };
export var getUserRoles = function (state) { return state.user.userRoles; };
export var getUserAccess = function (state) { return state.user.userAccess; };
export var getActiveUser = function (state) { return state.user.activeUser; };
export var getUserAchievements = function (state) { return state.user.userAchievements; };
