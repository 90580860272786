import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { $api } from 'shared/api/api';
import { BlockLoader } from 'shared/ui/BlockLoader';
import StatisticTable from 'shared/ui/Statistic/Table';
import { Title } from 'shared/ui/Title';
import { Wrapper } from 'shared/ui/Wrapper';
import cls from 'shared/ui/Statistic/Statistic.module.scss';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { Portal } from 'shared/ui/Portal';
var COLUMNS = {
    pos: '№',
    name: 'Шаг',
    question: 'Вопрос',
    answer: 'Ответ',
    score: 'Баллы',
    time: 'Время прохождения',
    // finished: 'Пройден',
};
export default function LessonStepsPage() {
    var lessonId = useParams().lessonId;
    var searchParams = useSearchParams()[0];
    var email = searchParams.get('user');
    var lessonName = searchParams.get('lesson');
    var _a = useState(null), steps = _a[0], setSteps = _a[1];
    var _b = useState(), content = _b[0], setContent = _b[1];
    var _c = useForm(), register = _c.register, handleSubmit = _c.handleSubmit;
    var onSubmit = function (state) {
        var _a;
        $api.post('/ext-course/frontend/manage/statistic/lesson-steps', { lessonId: lessonId, email: ((_a = state.email) === null || _a === void 0 ? void 0 : _a.length) ? state.email : email })
            .then(function (d) { return setSteps(d.data); });
    };
    var secondsToHMS = function (seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);
        var remainingSeconds = seconds % 60;
        var result = "".concat((hours < 10 ? '0' : '') + hours, ":").concat(minutes < 10 ? '0' : '').concat(minutes, ":").concat(remainingSeconds < 10 ? '0' : '').concat(remainingSeconds);
        return result;
    };
    useEffect(function () {
        $api.post('/ext-course/frontend/manage/statistic/lesson-steps', { lessonId: lessonId, email: email })
            .then(function (d) { return setSteps(d.data); });
    }, []);
    return (_jsxs(Wrapper, { className: cls.wrapper, children: [_jsxs(Title, { children: ["\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F \u0443\u0440\u043E\u043A\u0430 ", _jsx("br", {}), " ", lessonName !== null ? "'".concat(lessonName, "'") : ''] }), steps === null ? _jsx(BlockLoader, {})
                : (_jsx(_Fragment, { children: _jsx(StatisticTable, { columns: COLUMNS, children: steps.lesson_exercises && steps.lesson_exercises.map(function (step, index) { return (_jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { children: index + 1 }), _jsx("td", { children: step.exercise_info.name }), _jsx("td", { children: _jsx(Button, { onClick: function () { return setContent(step.exercise_info.content); }, children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C" }) }), _jsx("td", { children: step.answer }), _jsx("td", { children: step.exercise_info.points }), _jsx("td", { children: step.finished ? 'Да' : 'Нет' })] }) }, index)); }) }) })), _jsx(Portal, { children: _jsx(Modal
                // classNameContent={cls.modal}
                , { 
                    // classNameContent={cls.modal}
                    isOpen: !!content, onClose: function () { return setContent(undefined); }, children: _jsx("div", { dangerouslySetInnerHTML: { __html: content } }) }) })] }));
}
