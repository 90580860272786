import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    questions: [],
};
export var questionsSlice = createSlice({
    name: 'questions',
    initialState: initialState,
    reducers: {},
});
export var questionsActions = questionsSlice.actions;
export var questionsReducer = questionsSlice.reducer;
