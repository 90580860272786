import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import SVG403 from './403.svg';
import SVG404 from './404.svg';
import SVG429 from './429.svg';
import SVG5XX from './5xx.svg';
import cls from './Error.module.scss';
var getMessage = function (code) {
    switch (code) {
        case '403': return {
            message: 'Доступ запрещен',
            description: 'У вас нет разрешения на доступ к запрошенной странице',
            image: _jsx(SVG403, {}),
        };
        case '404': return {
            message: '404',
            description: 'Упс! Страница, которую вы ищите была удалена, либо вовсе не существовала',
            image: _jsx(SVG404, {}),
        };
        case '429': return {
            message: 'Превышен лимит запросов',
            description: 'На данный момент эта ссылка была автоматически отключена',
            image: _jsx(SVG429, {}),
        };
        default: return {
            message: 'Внутренняя ошибка сервера',
            description: 'Что-то пошло не так... Мы уже работаем над проблемой',
            image: _jsx(SVG5XX, {}),
        };
    }
};
export var Error = function (props) {
    var type = props.type;
    var message = getMessage(type);
    var buttonHandler = function () {
        window.location.href = '/';
    };
    return (_jsx("div", { className: cls.main_page, children: _jsxs("div", { className: cls.errorPageContainer, children: [_jsxs("div", { className: "error-page-description ".concat(type === '404' ? ' error-page-description-404' : ''), children: [_jsx("h1", { children: message.message }), _jsx("p", { children: message.description }), _jsx("div", { onClick: buttonHandler, role: "presentation", className: cls.errorPageButton, children: _jsxs("p", { children: [_jsx(Link, { to: "/", children: "\u041E\u0442\u043A\u0440\u044B\u0442\u044C" }), " \u0433\u043B\u0430\u0432\u043D\u0443\u044E \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443"] }) })] }), _jsx("div", { className: cls.errorPageSvg, children: message.image })] }) }));
};
