import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './CardEditButton.module.scss';
export var CardEditButton = function (_a) {
    var className = _a.className, contextMenu = _a.contextMenu;
    var _b = useState(false), isActive = _b[0], setIsActive = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("button", { className: classNames(cls.CardEditButton, {}, [className]), type: "button", onClick: function () { setIsActive(!isActive); }, children: [_jsx("svg", { width: "5", height: "5", viewBox: "0 0 5 5", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("circle", { cx: "2.5", cy: "2.5", r: "2.5", fill: "#9C9EAC" }) }), _jsx("svg", { width: "5", height: "5", viewBox: "0 0 5 5", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("circle", { cx: "2.5", cy: "2.5", r: "2.5", fill: "#9C9EAC" }) }), _jsx("svg", { width: "5", height: "5", viewBox: "0 0 5 5", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("circle", { cx: "2.5", cy: "2.5", r: "2.5", fill: "#9C9EAC" }) })] }), isActive
                && (_jsx("div", { className: cls.contextMenu, onClick: function () { setIsActive(!isActive); }, children: contextMenu.map(function (element, index) { return (_jsx("div", { className: cls.contextMenuElement, children: element }, index)); }) }))] }));
};
