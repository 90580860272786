import { createSlice } from '@reduxjs/toolkit';
import { getGourseDetailStatisticData } from '../services/getCourseDetailStatistic';
var initialState = {
    isLoading: false,
    error: false,
    data: null,
};
export var courseDetailSlice = createSlice({
    name: 'courseDetailStatistic',
    initialState: initialState,
    reducers: {
        setData: function (state, action) {
            state.data = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getGourseDetailStatisticData.pending, function (state) {
            state.isLoading = true;
            state.error = false;
        })
            .addCase(getGourseDetailStatisticData.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = false;
            state.data = action.payload;
        })
            .addCase(getGourseDetailStatisticData.rejected, function (state) {
            state.isLoading = false;
            state.error = true;
        });
    },
});
export var courseDetailActions = courseDetailSlice.actions;
export var courseDetailReducer = courseDetailSlice.reducer;
