import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import { Button, ButtonTheme } from 'shared/ui/Button';
import ArrowBack from 'shared/assets/icons/arrowBack.svg';
import { useDispatch } from 'react-redux';
import { chatActions } from 'entities/Chat/model/slice/chatSlice';
import cls from './ChatWidget.module.scss';
export var ChatWidget = function (props) {
    var className = props.className;
    var dispatch = useDispatch();
    return (_jsxs("div", { className: classNames(cls.ChatWidget, {}, [className]), children: [_jsx(Button, { icon: _jsx(ArrowBack, {}), className: cls.buttonBack, theme: ButtonTheme.BUTTON_MODAL_TEXT, onClick: function () { return dispatch(chatActions.toggleChatModal()); }, children: "\u041D\u0430\u0437\u0430\u0434" }), _jsx("div", { className: cls.text, children: "\u0427\u0430\u0442 \u043F\u043E\u043A\u0430 \u0432 \u0440\u0430\u0437\u0440\u0430\u0431\u043E\u0442\u043A\u0435" })] }));
};
