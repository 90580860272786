import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserAuthData, getUserIsLoading } from 'entities/User/model/selectors/getUser';
import { PageLoader } from 'shared/ui/PageLoader';
var ProtectedRoute = function (props) {
    // return <Outlet />;
    var 
    // isAllowed,
    redirectPath = props.redirectPath;
    var isLoading = useSelector(getUserIsLoading);
    var authData = useSelector(getUserAuthData);
    if (isLoading) {
        return _jsx(PageLoader, {});
    }
    if (authData) {
        return _jsx(Outlet, {});
    }
    // console.log(555, authData);
    return _jsx(Navigate, { to: redirectPath || '/login', replace: true });
    //
    // if (!isAllowed) {
    //     return <Navigate to={redirectPath || '/login'} replace />;
    // }
    //
    // return <Outlet />;
};
export default ProtectedRoute;
