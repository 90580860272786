import { createSlice } from '@reduxjs/toolkit';
import { getTasks } from 'entities/Task/model/services/getTasks';
import { getTaskById } from 'entities/Task/model/services/getTaskById';
import { fetchTaskInLesson } from 'entities/Task/model/services/fetchTaskInLesson';
import { sendTask } from 'entities/Task/model/services/sendTask';
var initialState = {
    isLoading: false,
    isButtonLoading: false,
    taskList: {},
    activeTask: {},
    taskLessonList: [],
};
export var taskSlice = createSlice({
    name: 'task',
    initialState: initialState,
    reducers: {
        toggleButtonLoading: function (state, action) {
            state.isButtonLoading = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            // getTasks
            .addCase(getTasks.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getTasks.fulfilled, function (state, action) {
            state.isLoading = false;
            state.taskList = action.payload;
        })
            .addCase(getTasks.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getTaskById
            .addCase(getTaskById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getTaskById.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeTask = action.payload;
        })
            .addCase(getTaskById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // fetchTaskInLesson
            .addCase(fetchTaskInLesson.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchTaskInLesson.fulfilled, function (state, action) {
            state.isLoading = false;
            state.taskLessonList = action.payload;
        })
            .addCase(fetchTaskInLesson.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // sendTask
            .addCase(sendTask.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(sendTask.fulfilled, function (state, action) {
            state.isLoading = false;
            state.taskLessonList = action.payload;
        })
            .addCase(sendTask.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var taskActions = taskSlice.actions;
export var taskReducer = taskSlice.reducer;
