import { useEffect, useState } from 'react';
/**
 * @description Хук для отслеживания изменения размера окна браузера
 */
export var useWindowResolution = function () {
    var _a = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    }), windowSize = _a[0], setWindowSize = _a[1];
    useEffect(function () {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowSize;
};
