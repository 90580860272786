import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestResults } from 'widgets/TestPassingResultsWidget/api';
import { useWindowResolution } from 'shared/lib/hooks/useWindowResolution';
import { getTestResults } from 'entities/Test/model/selectors/getTest';
import cls from './TestPassingResultsWidget.module.scss';
var TestPassingResultsWidget = function (_a) {
    var testPassingId = _a.testPassingId;
    var dispatch = useDispatch();
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var width = useWindowResolution().width;
    var results = useSelector(getTestResults);
    useEffect(function () {
        dispatch(fetchTestResults(testPassingId));
    }, [testPassingId]);
    return (_jsxs("div", { children: [_jsx("h3", { style: { marginBottom: 8 }, children: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F \u0443\u0440\u043E\u043A\u0430" }), width > 1159
                ? (_jsxs("table", { className: "table", children: [_jsx("thead", { className: cls.tableHeader, children: _jsxs("tr", { children: [_jsx("th", { children: "\u2116" }), _jsx("th", { children: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435" }), _jsx("th", { children: "\u041E\u043F\u0443\u0431\u043B\u0438\u043A\u043E\u0432\u0430\u043D" }), _jsx("th", { children: "\u041D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u043E \u043E\u0442\u0432\u0435\u0442\u043E\u0432" }), _jsx("th", { children: "\u041F\u0440\u043E\u0445\u043E\u0434\u0438\u043B" }), _jsx("th", { children: "\u041A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u043E" }), _jsx("th", { children: "\u0411\u0430\u043B\u043B\u044B" }), _jsx("th", { children: "\u0412\u0440\u0435\u043C\u044F \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F" }), _jsx("th", {})] }) }), _jsx("tbody", { children: results === null || results === void 0 ? void 0 : results.map(function (result, index) { return (_jsxs("tr", { children: [_jsx("td", { children: index + 1 }), _jsx("td", { children: result.name }), _jsx("td", { children: result.publish }), _jsx("td", { children: result.multi_answer }), _jsx("td", { children: result.passed }), _jsx("td", { children: result.correct }), _jsx("td", { children: result.balls }), _jsx("td", { children: result.work_time })] }, index)); }) }, 1)] }))
                : (results === null || results === void 0 ? void 0 : results.map(function (result, index) { return (_jsx("div", { className: cls.card, children: _jsxs("div", { className: cls.userCard__content, children: [_jsxs("p", { children: [_jsx("b", { children: "\u2116: " }), index + 1] }), _jsxs("p", { children: [_jsx("b", { children: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435:" }), " ", result.name] }), _jsxs("p", { children: [_jsx("b", { children: "\u041E\u043F\u0443\u0431\u043B\u0438\u043A\u043E\u0432\u0430\u043D:" }), " ", result.publish] }), _jsxs("p", { children: [_jsx("b", { children: "\u041D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u043E \u043E\u0442\u0432\u0435\u0442\u043E\u0432:" }), " ", result.multi_answer] }), _jsxs("p", { children: [_jsx("b", { children: "\u041F\u0440\u043E\u0445\u043E\u0434\u0438\u043B:" }), " ", result.passed] }), _jsxs("p", { children: [_jsx("b", { children: "\u041A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u043E:" }), " ", result.correct] }), _jsxs("p", { children: [_jsx("b", { children: "\u0411\u0430\u043B\u043B\u044B:" }), " ", result.balls] }), _jsxs("p", { children: [_jsx("b", { children: "\u0412\u0440\u0435\u043C\u044F \u043F\u0440\u043E\u0445\u043E\u0436\u0434\u0435\u043D\u0438\u044F:" }), " ", result.work_time] })] }) }, index)); }))] }));
};
export { TestPassingResultsWidget };
