import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Input } from 'shared/ui/FormElements/Input/Input';
import { classNames } from 'shared/lib/classNames/classNames';
import { Form } from 'shared/ui/FormElements/Form/Form';
import { loginByEmail } from '../../model/services/loginByEmail/loginByEmail';
import cls from './LoginForm.module.scss';
var PersonalDataSchema = Yup.object().shape({
    email: Yup.string()
        .email('Неправильный e-mail')
        .required('Укажите email'),
    password: Yup.string().required('Укажите пароль'),
});
export var LoginForm = function (_a) {
    var className = _a.className;
    var dispatch = useDispatch();
    return (_jsx("div", { className: classNames(cls.LoginForm, {}, [className]), children: _jsxs(Form, { submitText: "\u0412\u043E\u0439\u0442\u0438", 
            /* submitButtonProps={...} */
            onSubmit: function (_a, setError) {
                var password = _a.password, email = _a.email;
                dispatch(loginByEmail({ password: password, email: email }));
            }, children: [_jsx(Input, { name: "email", placeholder: "\u041F\u043E\u0447\u0442\u0430" }), _jsx(Input, { name: "password", placeholder: "\u041F\u0430\u0440\u043E\u043B\u044C" })] }) }));
};
