import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTitles } from 'entities/Support/model/services/getTitles';
import { getTitlesFromState } from 'entities/Support/model/selectors/getSupport';
import CloseButton from 'shared/assets/icons/closeButton.svg';
import { Button } from 'shared/ui/Button';
import { closeMessage } from 'entities/Support/model/services/closeMessage';
import { getDate } from 'shared/lib/utils/auxiliaryFunction';
import { useWindowResolution } from 'shared/lib/hooks/useWindowResolution';
import cls from './ListOfReferences.module.scss';
// const supportData = [
//     {
//         id: 1,
//         date: '14.11.2023',
//         theme: 'Не открывается обучающий курс',
//         messages: '8',
//         status: 'Открыта',
//         number: '#01428917',
//     },
//     {
//         id: 2,
//         date: '14.11.2023',
//         theme: 'Опечатка в уроке',
//         messages: '1',
//         status: 'Открыта',
//         number: '#01428916',
//     },
//     {
//         id: 3,
//         date: '14.11.2023',
//         theme: 'Не получается завершить тест ошибка',
//         messages: '3',
//         status: 'Закрыта',
//         number: '#01428917',
//     },
//     {
//         id: 4,
//         date: '14.11.2023',
//         theme: 'Не прикрепляется файл',
//         messages: '5',
//         status: 'Закрыта',
//         number: '#01428917',
//     },
//     {
//         id: 5,
//         date: '14.11.2023',
//         theme: 'Не открывается обучающий курс',
//         messages: '5',
//         status: 'Закрыта',
//         number: '#01428917',
//     },
// ];
export var ListOfReferences = function (props) {
    var className = props.className;
    var navigation = useNavigate();
    var dispatch = useDispatch();
    var supportData = useSelector(getTitlesFromState);
    var windowResolution = useWindowResolution();
    useEffect(function () {
        dispatch(getTitles({}));
    }, []);
    var closeMessageHandler = function (e, id) {
        e.stopPropagation();
        var promise = dispatch(closeMessage({ id: id }));
        promise.then(function (res) {
            if (res.payload === true)
                dispatch(getTitles({}));
        });
    };
    return (_jsxs("div", { className: classNames(cls.ListOfReferences, {}, [className]), children: [_jsxs("table", { className: cls.table, children: [_jsx("tbody", { className: cls.header, children: _jsxs("tr", { children: [_jsx("th", { children: "\u0414\u0430\u0442\u0430" }), _jsx("th", { children: "\u0422\u0435\u043C\u0430" }), _jsx("th", { children: "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F" }), _jsx("th", { children: "\u0421\u0442\u0430\u0442\u0443\u0441" }), _jsx("th", { children: "\u041D\u043E\u043C\u0435\u0440 \u0437\u0430\u044F\u0432\u043A\u0438" })] }) }), supportData.map(function (application) { return (windowResolution.width > 931
                        ? (_jsx("tbody", { children: _jsxs("tr", { className: application.status === 0 ? cls.table__clickable : classNames(cls.table__clickable, {}, [cls.close]), onClick: function () {
                                    navigation("/support/".concat(application.id));
                                }, children: [_jsx("td", { children: getDate(application.date) }), _jsx("td", { children: application.title }), _jsx("td", { children: _jsx("div", { className: application.isNew === 0 ? cls.badge : classNames(cls.badge, {}, [cls.new]), children: application.count }) }), _jsx("td", { children: application.status === 0 ? 'Открыта' : 'Закрыта' }), _jsx("td", { children: application.number }), _jsx("td", { children: application.status === 0
                                            && (_jsx(Button, { icon: _jsx(CloseButton, {}), onClick: function (e) { return closeMessageHandler(e, application.id); } })) })] }) }, application.id)) : (_jsxs("div", { className: cls.userCard, children: [_jsxs("div", { className: cls.userCard__content, children: [_jsxs("p", { children: [_jsx("b", { children: "\u0414\u0430\u0442\u0430:" }), " ", getDate(application.date)] }), _jsxs("p", { children: [_jsx("b", { children: "\u0422\u0435\u043C\u0430:" }), " ", application.title] }), _jsxs("p", { children: [_jsx("b", { children: "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F:" }), " ", application.count] }), _jsxs("p", { children: [_jsx("b", { children: "\u0421\u0442\u0430\u0442\u0443\u0441:" }), " ", application.status === 0 ? 'Открыта' : 'Закрыта'] }), _jsxs("p", { children: [_jsx("b", { children: "\u2116 \u0437\u0430\u044F\u0432\u043A\u0438: " }), application.number] })] }), _jsx("div", { className: cls.userCard__buttons, children: application.status === 0
                                    && (_jsx(Button, { icon: _jsx(CloseButton, {}), onClick: function (e) { return closeMessageHandler(e, application.id); } })) })] }, application.id))); })] }), supportData.length === 0 && _jsx("p", { className: cls.empty, children: "\u0423 \u0432\u0430\u0441 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u043E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u0439 \u0432 \u0442\u0435\u0445\u043D\u0438\u0447\u0435\u0441\u043A\u0443\u044E \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443" })] }));
};
