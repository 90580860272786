import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppLink } from 'shared/ui/AppLink';
import { Navigation } from 'features/Navigation';
import { classNames } from 'shared/lib/classNames';
import { AppLinkTheme } from 'shared/ui/AppLink/AppLink';
import Logo from 'shared/assets/icons/logo.svg';
import cls from './Sidebar.module.scss';
export var Sidebar = function (props) {
    var className = props.className;
    return (_jsxs("aside", { className: classNames(cls.Sidebar, {}, [className]), children: [_jsx("div", { className: cls.logo, children: _jsx(AppLink, { to: "/", className: cls.logoLink, theme: AppLinkTheme.NAVIGATION, icon: _jsx(Logo, {}) }) }), _jsx(Navigation, {})] }));
};
