export function JsonToFormData(formData, data, parentKey) {
    if (parentKey === void 0) { parentKey = undefined; }
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(function (key) {
            JsonToFormData(formData, data[key], parentKey ? "".concat(parentKey, "[").concat(key, "]") : key);
        });
    }
    else {
        var value = data == null ? '' : data;
        // @ts-ignore
        formData.append(parentKey, value);
    }
}
