import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import { useEffect, useState } from 'react';
import cls from './Tab.module.scss';
var isActiveTabOutOfRange = function (items, activeTab) {
    if (items.length < +activeTab)
        return true;
    return false;
};
export var Tab = function (props) {
    var className = props.className, classNameList = props.classNameList, items = props.items, defaultTab = props.defaultTab, children = props.children, changesTab = props.changesTab, tabItemClassName = props.tabItemClassName;
    var _a = useState(defaultTab || '1'), activeTab = _a[0], setActiveTab = _a[1];
    var changeTab = function (value) {
        setActiveTab(value);
        changesTab && changesTab(value);
    };
    useEffect(function () {
        if (defaultTab)
            setActiveTab(defaultTab);
    }, [defaultTab]);
    if (isActiveTabOutOfRange(items, activeTab)) {
        setActiveTab(JSON.stringify(items.length));
    }
    return (_jsxs("div", { className: classNames(cls.Tab, {}, [className]), children: [_jsx("div", { className: "".concat(cls.list, " ").concat(classNameList), children: items.map(function (item, i) {
                    var _a;
                    var tabId = String(i + 1);
                    if (!item.hidden) {
                        return (_jsx("button", { type: "button", disabled: item.disabled === undefined ? false : !item.disabled, className: classNames(cls.name, (_a = {},
                                _a[cls.active] = activeTab === tabId,
                                _a[cls.disabled] = item.disabled === undefined ? false : !item.disabled,
                                _a), [tabItemClassName]), onClick: function () { return changeTab(tabId); }, children: item.label }, item.label));
                    }
                    return null;
                }) }), children, _jsx("div", { className: cls.content, children: items.map(function (item, i) {
                    var _a;
                    var tabId = String(i + 1);
                    if (!item.hidden) {
                        return (_jsx("div", { className: classNames(cls.item, (_a = {}, _a[cls.active] = activeTab === tabId, _a), []), children: item.itemContent }, item.label));
                    }
                    return null;
                }) })] }));
};
