import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Wrapper.module.scss';
export var ThemeWrapper;
(function (ThemeWrapper) {
    ThemeWrapper["PRIMARY"] = "primary";
})(ThemeWrapper || (ThemeWrapper = {}));
export var Wrapper = function (props) {
    var className = props.className, children = props.children, _a = props.theme, theme = _a === void 0 ? '' : _a;
    return (_jsx("div", { className: classNames(cls.Wrapper, {}, [className, cls[theme]]), children: children }));
};
