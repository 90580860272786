import { createSlice } from '@reduxjs/toolkit';
import { deleteGlossary } from '../services/deleteGlossary';
import { createGlossary } from '../services/createGlossary';
import { updateGlossary } from '../services/updateGlossary';
var initialState = {
    isLoading: false,
};
export var glossaryEditSlice = createSlice({
    name: 'glossaryEdit',
    initialState: initialState,
    reducers: {
        toggleChatModal: function (state) {
            state.isLoading = !state.isLoading;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(createGlossary.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(createGlossary.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(createGlossary.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(updateGlossary.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(updateGlossary.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(updateGlossary.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(deleteGlossary.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(deleteGlossary.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(deleteGlossary.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var glossaryEditActions = glossaryEditSlice.actions;
export var glossaryEditReducer = glossaryEditSlice.reducer;
