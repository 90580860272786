// Преобразует дату из маски формата dd.mm.yyyy в формат ISO yyyy-mm-dd или yyyy/mm/dd
export var convertsDateToIsoFormat = function (date, separator) {
    if (separator === void 0) { separator = '-'; }
    var newDate = date.split('.').reverse().join(separator);
    return newDate;
};
// Преобразует дату из ISO формата в дату вида dd.mm.yyyy
export var localDate = function (date) { return (date ? new Date(date).toLocaleDateString('ru-RU') : null); };
// Разбивает массив на подмассивы
export var chunkArray = function (array, chunkSize) {
    var result = [];
    for (var i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};
export var getTime = function (date) {
    var dateToString = date ? new Date(date) : new Date();
    var time = [
        dateToString.getHours(),
        dateToString.getMinutes(),
        dateToString.getSeconds()
    ].map(function (x) { return (x < 10 ? "0".concat(x) : x); }).join(':');
    return time;
};
export var getDate = function (date) {
    var dateToString = date ? new Date(date) : new Date();
    var newDate = [
        dateToString.getDate(),
        dateToString.getMonth() + 1,
        dateToString.getFullYear()
    ].map(function (x) { return (x < 10 ? "0".concat(x) : x); }).join('.');
    return newDate;
};
export var dateNow = function () {
    var date = new Date();
    var newDate = "\n    ".concat(date.getFullYear(), "-\n    ").concat((+date.getMonth() + 1) < 10 ? "0\n        ".concat(date.getMonth() + 1) : date.getMonth() + 1, "-\n        ").concat(+date.getDate() < 10 ? "0".concat(date.getDate()) : date.getDate(), "\n        ").concat(+date.getHours() < 10 ? "0".concat(date.getHours()) : date.getHours(), ":\n        ").concat(+date.getMinutes() < 10 ? "0".concat(date.getMinutes()) : date.getMinutes(), ":\n        ").concat(+date.getSeconds() < 10 ? "0".concat(date.getSeconds()) : date.getSeconds());
    return newDate;
};
export var compareTwoDate = function (startDate, endDate) {
    var parseDate = function (dateString) {
        var _a = dateString.split('.').map(Number), day = _a[0], month = _a[1], year = _a[2];
        return new Date(year, month - 1, day);
    };
    // const currentDate = new Date();
    var start = parseDate(startDate);
    var end = parseDate(endDate);
    if (startDate === '' || endDate === '') {
        return '';
    }
    // if (start <= currentDate) {
    //     return 'Дата начала должна быть больше текущей даты';
    // }
    //
    // if (end <= currentDate) {
    //     return 'Дата окончания должна быть больше текущей даты';
    // }
    if (start > end) {
        return 'Дата начала должна быть меньше даты окончания';
    }
    return '';
};
export var filterTestsByCourseName = function (testsObj, courseName) {
    var filteredTests = {};
    // eslint-disable-next-line no-restricted-syntax
    for (var key in testsObj) {
        if (Object.prototype.hasOwnProperty.call(testsObj, key)) {
            var filtered = testsObj[key].filter(function (test) { return test.binding_info.course &&
                test.binding_info.course.name.includes(courseName); });
            if (filtered.length > 0) {
                filteredTests[key] = filtered;
            }
        }
    }
    return filteredTests;
};
