import { createSlice } from '@reduxjs/toolkit';
import { createTask } from 'features/TaskEdit/services/createTask';
import { updateTask } from 'features/TaskEdit/services/updateTask';
import { deleteTask } from 'features/TaskEdit/services/deleteTask';
var initialState = {
    isLoading: false,
    isCreateLoading: false,
    isModalOpen: false,
    search: '',
    type: undefined,
};
export var taskEditSlice = createSlice({
    name: 'task',
    initialState: initialState,
    reducers: {
        toggleTaskEditModal: function (state) {
            state.isModalOpen = !state.isModalOpen;
        },
        changeSelectedTask: function (state, action) {
            state.selectedTask = action.payload;
        },
        toggleTaskTeamModal: function (state) {
            state.isModalOpen = !state.isModalOpen;
        },
        setSearch: function (state, action) {
            state.search = action.payload;
        },
        setType: function (state, action) {
            state.type = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(createTask.pending, function (state) {
            state.error = undefined;
            state.isCreateLoading = true;
        })
            .addCase(createTask.fulfilled, function (state) {
            state.isCreateLoading = false;
        })
            .addCase(createTask.rejected, function (state, action) {
            state.isCreateLoading = false;
            state.error = action.payload;
        })
            .addCase(updateTask.pending, function (state) {
            state.error = undefined;
            state.isCreateLoading = true;
        })
            .addCase(updateTask.fulfilled, function (state) {
            state.isCreateLoading = false;
        })
            .addCase(updateTask.rejected, function (state, action) {
            state.isCreateLoading = false;
            state.error = action.payload;
        })
            .addCase(deleteTask.pending, function (state) {
            state.error = undefined;
        })
            .addCase(deleteTask.fulfilled, function (state) {
            state.isCreateLoading = false;
        })
            .addCase(deleteTask.rejected, function (state, action) {
            state.isCreateLoading = false;
            state.error = action.payload;
        });
    },
});
export var taskEditActions = taskEditSlice.actions;
export var taskEditReducer = taskEditSlice.reducer;
