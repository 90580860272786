import { createSlice } from '@reduxjs/toolkit';
import { getCoursesAndTests } from 'shared/model/services/getCoursesAndTests';
import { getLineChart } from '../services/getLineChart';
var initialState = {
    isLoading: false,
    error: false,
    data: null,
    coursesAndTests: null,
    generatedEduStats: null,
};
export var lineChartSlice = createSlice({
    name: 'lineChart',
    initialState: initialState,
    reducers: {
        setGeneratedEduStats: function (state, action) {
            state.generatedEduStats = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getLineChart.rejected, function (state) {
            state.isLoading = false;
            state.error = true;
        })
            .addCase(getLineChart.pending, function (state) {
            state.isLoading = true;
            state.error = false;
        })
            .addCase(getLineChart.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = false;
            state.data = action.payload;
        })
            .addCase(getCoursesAndTests.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = false;
            state.coursesAndTests = action.payload;
        })
            .addCase(getCoursesAndTests.rejected, function (state) {
            state.isLoading = false;
            state.error = true;
        })
            .addCase(getCoursesAndTests.pending, function (state) {
            state.isLoading = true;
            state.error = false;
        });
    },
});
export var lineChartActions = lineChartSlice.actions;
export var lineChartReducer = lineChartSlice.reducer;
