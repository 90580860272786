import { createSlice } from '@reduxjs/toolkit';
import { deleteCourseClubs } from 'entities/Course/model/services/deleteCourseClubs';
import { getCourseCertificates } from 'entities/Course/model/services/getCourseCertificates';
import { getCourseExperts } from 'entities/Course/model/services/getCourseExperts';
import { getBindCourseExperts } from 'entities/Course/model/services/getBindCourseExperts';
import { getCourseById } from '../services/getCourseById';
import { getCoursesLessons } from '../services/getCoursesLessons';
import { updateCourse } from '../services/updateCourse';
import { createCourse } from '../services/createCourse';
import { deleteCourse } from '../services/deleteCourse';
import { setCourseClubs } from '../services/setCourseClubs';
import { fetchCourseTests } from '../services/fetchCourseTests';
var initialState = {
    isLoading: false,
    isSetClubsLoading: false,
    coursesList: {},
    isShortView: false,
    alphabetFilter: null,
    dateFilter: null,
    activeCourse: {},
    imageSize: 0,
    themeFilter: null,
    certificates: null,
    expertList: null,
    expertBindList: null,
    visibility: 'all',
};
export var courseSlice = createSlice({
    name: 'course',
    initialState: initialState,
    reducers: {
        setCardView: function (state, action) {
            state.isShortView = action.payload === 'short';
        },
        setActiveCourse: function (state, action) {
            // if (state.activeCourse.tests) {
            //     state.activeCourse = {
            //         ...state.activeCourse,
            //         tests: action.payload,
            //     };
            // }
            state.activeCourse = action.payload;
        },
        updateProjectsList: function (state, action) {
            state.activeCourse.projects = action.payload;
        },
        setAlphabetFilter: function (state, action) {
            state.alphabetFilter = action.payload;
        },
        setDateFilter: function (state, action) {
            state.dateFilter = action.payload;
        },
        setThemeFilter: function (state, action) {
            state.themeFilter = action.payload;
        },
        setVisibility: function (state, action) {
            state.visibility = action.payload;
        },
        setImageSize: function (state, action) {
            state.imageSize = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            // getCoursesLessons
            .addCase(getCoursesLessons.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getCoursesLessons.fulfilled, function (state, action) {
            state.isLoading = false;
            state.coursesList = action.payload;
        })
            .addCase(getCoursesLessons.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getCourseById
            .addCase(getCourseById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getCourseById.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeCourse = action.payload;
        })
            .addCase(getCourseById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getBindedTests
            .addCase(fetchCourseTests.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchCourseTests.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeCourse.tests = action.payload;
        })
            .addCase(fetchCourseTests.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // setCourseClubs
            .addCase(setCourseClubs.pending, function (state) {
            state.error = undefined;
            state.isSetClubsLoading = true;
        })
            .addCase(setCourseClubs.fulfilled, function (state, action) {
            state.isSetClubsLoading = false;
            state.activeCourse.groups = action.payload.post;
        })
            .addCase(setCourseClubs.rejected, function (state, action) {
            state.isSetClubsLoading = false;
            state.error = action.payload;
        })
            // deleteCourseClubs
            .addCase(deleteCourseClubs.pending, function (state) {
            state.error = undefined;
            // state.isSetClubsLoading = true;
        })
            .addCase(deleteCourseClubs.fulfilled, function (state, action) {
            // state.isSetClubsLoading = false;
            state.activeCourse.groups = action.payload.post;
        })
            .addCase(deleteCourseClubs.rejected, function (state, action) {
            // state.isSetClubsLoading = false;
            state.error = action.payload;
        })
            // updateCourse
            .addCase(updateCourse.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(updateCourse.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeCourse = action.payload;
        })
            .addCase(updateCourse.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(createCourse.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(createCourse.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeCourse = action.payload;
        })
            .addCase(createCourse.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(deleteCourse.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(deleteCourse.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(deleteCourse.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getCourseCertificates.fulfilled, function (state, action) {
            state.certificates = action.payload;
            state.isLoading = false;
        })
            .addCase(getCourseCertificates.pending, function (state) {
            state.isLoading = true;
        })
            .addCase(getCourseCertificates.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getCourseExperts.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getCourseExperts.pending, function (state) {
            state.isLoading = true;
        })
            .addCase(getCourseExperts.fulfilled, function (state, action) {
            state.expertList = action.payload.experts;
            state.isLoading = false;
        })
            .addCase(getBindCourseExperts.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getBindCourseExperts.pending, function (state) {
            state.isLoading = true;
        })
            .addCase(getBindCourseExperts.fulfilled, function (state, action) {
            state.expertBindList = action.payload.experts;
            state.isLoading = false;
        });
        // .addCase(getCourseImageSize.pending, (state) => {
        //     state.isLoading = true;
        //     state.error = undefined;
        // })
        // .addCase(getCourseImageSize.fulfilled, (state, action: PayloadAction<number>) => {
        //     state.isLoading = false;
        //     state.error = undefined;
        //     state.imageSize = action.payload;
        // })
        // .addCase(getCourseImageSize.rejected, (state, action) => {
        //     state.isLoading = false;
        //     state.error = action.payload;
        // });
    },
});
export var courseActions = courseSlice.actions;
export var courseReducer = courseSlice.reducer;
