import { createSlice } from '@reduxjs/toolkit';
import { fetchGlossary } from '../services/fetchGlossary';
var initialState = {
    isLoading: false,
};
export var glossarySlice = createSlice({
    name: 'glossary',
    initialState: initialState,
    reducers: {
        addGlossaryItem: function (state, action) {
            var _a;
            (_a = state.glossaryList) === null || _a === void 0 ? void 0 : _a.push(action.payload);
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(fetchGlossary.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchGlossary.fulfilled, function (state, action) {
            state.isLoading = false;
            state.glossaryList = action.payload;
        })
            .addCase(fetchGlossary.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var glossaryActions = glossarySlice.actions;
export var glossaryReducer = glossarySlice.reducer;
