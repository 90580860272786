var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CardEditButton } from 'shared/ui/CardEditButton';
import { classNames } from 'shared/lib/classNames/classNames';
import { getCardView } from 'entities/Course/model/selectors/getCourse';
import defaultImage from 'shared/assets/img/pages/courses_bg.png';
import { AppLink } from 'shared/ui/AppLink';
import { Button } from 'shared/ui/Button';
import { getUserRoles } from 'entities/User/model/selectors/getUser';
import { localDate } from 'shared/lib/utils/auxiliaryFunction';
import { deleteCourse } from 'entities/Course/model/services/deleteCourse';
import { getCoursesLessons } from 'entities/Course/model/services/getCoursesLessons';
import { updateCourse } from 'entities/Course/model/services/updateCourse';
import cls from './CourseCard.module.scss';
export var CourseCard = function (props) {
    var _a;
    var className = props.className, data = props.data;
    var userRoles = useSelector(getUserRoles);
    var dispatch = useDispatch();
    var removeCardButtonHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm('Подвердите удаление'))
                        return [2 /*return*/];
                    return [4 /*yield*/, dispatch(deleteCourse({ courseId: data.id }))];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, dispatch(getCoursesLessons({}))];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var hideCardButtonHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(updateCourse({ course_id: data.id, publish: data.publish ? 0 : 1 }))];
                case 1:
                    _a.sent();
                    dispatch(getCoursesLessons({}));
                    return [2 /*return*/];
            }
        });
    }); };
    var isShortView = useSelector(getCardView);
    var cardEditOptions = [
        _jsx(AppLink, { to: "/courseEdit/".concat(data.id), children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }),
        _jsx(Button, { onClick: removeCardButtonHandler, children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }),
        _jsx(Button, { onClick: hideCardButtonHandler, children: data.publish ? 'Скрыть' : 'Убрать из скрытых' }),
    ];
    var maxLessons = (data === null || data === void 0 ? void 0 : data.lessons) ? Object.keys(data.lessons).length : null;
    var passedLessons = (data === null || data === void 0 ? void 0 : data.lessons) ? Object.values(data.lessons).reduce(function (completed, lesson) {
        if (lesson.progress === 100)
            return completed + 1;
        return completed;
    }, 0) : null;
    var maxTests = data.tests ? Object.keys(data.tests).length : null;
    var completedTests = data.tests ? Object.values(data.tests).filter(function (test) { return test.progress === 100; }).length : null;
    return (_jsxs("div", { className: classNames(cls.CourseCard, {}, [className]), children: [_jsx("div", { className: cls.header, children: _jsx("img", { className: cls.image, src: data.image ? "/api/uploads/".concat(data.image) : defaultImage, alt: "\u041F\u0440\u0435\u0432\u044C\u044E \u043A\u0443\u0440\u0441\u0430" }) }), _jsxs(Link, { className: cls.body, to: "/lessons/".concat(data === null || data === void 0 ? void 0 : data.id), children: [_jsx("h4", { className: cls.courseName, children: (data === null || data === void 0 ? void 0 : data.name) || 'Курс' }), _jsx("div", { className: cls.description, dangerouslySetInnerHTML: { __html: data === null || data === void 0 ? void 0 : data.description } }), !isShortView
                        && (_jsxs(_Fragment, { children: [_jsxs("p", { className: cls.startDate, children: ["\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430: ", _jsx("span", { children: localDate(data.active_from) || 'Доступен всегда' })] }), data.active_to
                                    && (_jsxs("p", { className: cls.startDate, children: ["\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F: ", _jsx("span", { children: localDate(data.active_to) })] })), _jsxs("p", { className: cls.theme, children: ["\u0422\u0435\u043C\u0430: ", _jsx("span", { children: (data === null || data === void 0 ? void 0 : data.theme) || 'Без темы' })] }), _jsxs("div", { className: cls.tags, children: [!!(data === null || data === void 0 ? void 0 : data.open_course)
                                            && (_jsx("div", { className: cls.tag, children: "#\u041E\u0442\u043A\u0440\u044B\u0442\u044B\u0439 \u043A\u0443\u0440\u0441" })), (data === null || data === void 0 ? void 0 : data.has_projects)
                                            && (_jsx("div", { className: cls.tag, children: "#\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0439 \u043A\u0443\u0440\u0441" })), (_a = data === null || data === void 0 ? void 0 : data.tags) === null || _a === void 0 ? void 0 : _a.map(function (tag) { return (_jsx("div", { className: cls.tag, children: tag }, tag)); })] }), _jsxs("div", { className: cls.statisticList, children: [_jsxs("p", { className: cls.statistics, children: ["\u041F\u0440\u043E\u0439\u0434\u0435\u043D\u043E \u0443\u0440\u043E\u043A\u043E\u0432: ", passedLessons || '0', " / ", maxLessons || '0'] }), _jsxs("p", { className: cls.statistics, children: ["\u041F\u0440\u043E\u0439\u0434\u0435\u043D\u043E \u0442\u0435\u0441\u0442\u043E\u0432: ", completedTests || '0', " / ", maxTests || '0'] })] })] }))] }), ((userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('SuperAdministrator')) || (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('Administrator')) || (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes('CourseEditor')))
                && (_jsx(CardEditButton, { contextMenu: cardEditOptions }))] }));
};
