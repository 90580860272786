import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { classNames } from 'shared/lib/classNames/classNames';
import { CourseCard } from 'entities/Course/ui/CourseCard/CourseCard';
import { getCourse, getCourseAlphabetFilter, getCourseDateFilter, getCourseVisibility, getThemeFilter, } from 'entities/Course/model/selectors/getCourse';
import { useEffect, useState } from 'react';
import { stringSearch } from 'shared/lib/utils/stringSearch';
import cls from './CourseList.module.scss';
export var CourseList = function (props) {
    var className = props.className, _a = props.defaultFilters, defaultFilters = _a === void 0 ? [] : _a, search = props.search;
    var _b = useState([]), filteredCourses = _b[0], setFilteredCourses = _b[1];
    var courses = useSelector(getCourse);
    var alphabetFilter = useSelector(getCourseAlphabetFilter);
    var dateFilter = useSelector(getCourseDateFilter);
    var themeFilter = useSelector(getThemeFilter);
    var courseVisibility = useSelector(getCourseVisibility);
    useEffect(function () {
        var _a, _b;
        if ((_a = courses === null || courses === void 0 ? void 0 : courses.coursesList) === null || _a === void 0 ? void 0 : _a.coursesLessons) {
            var listObj = Object.values((_b = courses === null || courses === void 0 ? void 0 : courses.coursesList) === null || _b === void 0 ? void 0 : _b.coursesLessons);
            if (defaultFilters.includes('courseStarted')) {
                listObj = listObj.filter(function (item) { return item.progress > 0 && item.progress !== 100; });
            }
            if (defaultFilters.includes('courseFinished')) {
                listObj = listObj.filter(function (item) { return item.progress === 100; });
            }
            if (alphabetFilter === 'alphabetStart') {
                listObj = listObj.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
            }
            if (alphabetFilter === 'alphabetEnd') {
                listObj = listObj.sort(function (a, b) { return (a.name < b.name ? 1 : -1); });
            }
            if (dateFilter === 'dateStartNew') {
                listObj = listObj.sort(function (a, b) {
                    var dateA = new Date(a.created_at);
                    var dateB = new Date(b.created_at);
                    return dateB - dateA;
                });
            }
            if (dateFilter === 'dateStartOld') {
                listObj = listObj.sort(function (a, b) {
                    var dateA = new Date(a.created_at);
                    var dateB = new Date(b.created_at);
                    return dateA > dateB ? 1 : -1;
                });
            }
            if (themeFilter) {
                listObj = listObj.filter(function (item) { return item.theme === themeFilter; });
            }
            if (search) {
                listObj = listObj.filter(function (item) { return stringSearch(item.name, search); });
            }
            setFilteredCourses(listObj);
        }
    }, [alphabetFilter, courses, search]);
    // useEffect(() => {
    //     setFilteredCourses();
    // }, [search]);
    if (courses.isLoading) {
        return (_jsx("div", { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430" }));
    }
    return (_jsx("div", { className: classNames(cls.CourseList, {}, [className]), children: courseVisibility === 'non-hidden' ? filteredCourses === null || filteredCourses === void 0 ? void 0 : filteredCourses.map(function (course) { return (course.publish === 1 && (_jsx(CourseCard, { data: course }, course.id))); }) : courseVisibility === 'hidden' ? filteredCourses === null || filteredCourses === void 0 ? void 0 : filteredCourses.map(function (course) { return (course.publish === 0 && (_jsx(CourseCard, { data: course }, course.id))); }) : filteredCourses === null || filteredCourses === void 0 ? void 0 : filteredCourses.map(function (course) { return (_jsx(CourseCard, { data: course }, course.id)); }) }));
};
