var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, } from 'react';
import { classNames } from 'shared/lib/classNames/classNames';
import ButtonSpinner from 'shared/assets/icons/buttonSpinner.svg';
import cls from './Button.module.scss';
export var ButtonTheme;
(function (ButtonTheme) {
    ButtonTheme["PRIMARY"] = "primary";
    ButtonTheme["ACCENT"] = "accent";
    ButtonTheme["INVERTED"] = "inverted";
    ButtonTheme["NAVIGATION"] = "navigation";
    ButtonTheme["FILE_LOADER"] = "file_loader";
    ButtonTheme["BUTTON_MODAL_TEXT"] = "buttonModalText";
    ButtonTheme["REDTEXT"] = "red-text";
})(ButtonTheme || (ButtonTheme = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["XL"] = "xl";
    ButtonSize["L"] = "l";
    ButtonSize["M"] = "m";
    ButtonSize["S"] = "s";
    ButtonSize["NULL"] = "null";
})(ButtonSize || (ButtonSize = {}));
export var Button = function (props) {
    var _a;
    var className = props.className, children = props.children, reverse = props.reverse, _b = props.theme, theme = _b === void 0 ? '' : _b, icon = props.icon, showSpinner = props.showSpinner, _c = props.width, width = _c === void 0 ? 'auto' : _c, _d = props.size, size = _d === void 0 ? '' : _d, otherProps = __rest(props, ["className", "children", "reverse", "theme", "icon", "showSpinner", "width", "size"]);
    var $button = useRef(null);
    useEffect(function () {
        if ($button.current)
            $button.current.style.setProperty('--custom-width', width);
    }, []);
    var mods = (_a = {},
        _a[cls.reverse] = reverse,
        _a);
    return (_jsxs("button", __assign({ type: "button", className: classNames(cls.Button, mods, [className, cls[theme], cls[size]]), ref: $button }, otherProps, { children: [showSpinner
                && (_jsx("span", { className: classNames(cls.spinnerBox, {}, []), children: _jsx(ButtonSpinner, { className: cls.spinner }) })), icon
                && (_jsx("span", { className: classNames(cls.icon, {}, []), children: icon })), children && _jsx("span", { className: cls.text, children: children })] })));
};
