import { createSlice } from '@reduxjs/toolkit';
import { fetchLessonTests } from 'features/LessonEdit/model/services/fetchLessonTests';
import { fetchLessonGlossary } from 'features/LessonEdit/model/services/fetchLessonGlossary';
import { createLessonStep } from 'features/LessonEdit/model/services/createLessonStep';
import { updateLessonStep } from 'features/LessonEdit/model/services/updateLessonStep';
import { fetchLessonById } from '../services/fetchLessonById';
import { fetchLessonSteps } from '../services/fetchLessonSteps';
import { fetchLessonTasks } from '../services/fetchLessonTasks';
import { updateLesson } from '../services/updateLesson';
import { createLesson } from '../services/createLesson';
var initialState = {
    isLoading: false,
    isInfoLoading: false,
    isStepsLoading: false,
    isTestsLoading: false,
    isTasksLoading: false,
    isGlossaryLoading: false,
};
export var lessonEditSlice = createSlice({
    name: 'lessonEdit',
    initialState: initialState,
    reducers: {
        setLessonStepsPoints: function (state, action) {
            state.lessonStepsPoints = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            // fetchLessonById
            .addCase(fetchLessonById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchLessonById.fulfilled, function (state, action) {
            state.isLoading = false;
            state.lessonInfo = action.payload;
        })
            .addCase(fetchLessonById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // createLesson
            .addCase(createLesson.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(createLesson.fulfilled, function (state, action) {
            state.isLoading = false;
            state.lessonInfo = action.payload;
        })
            .addCase(createLesson.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // updateLesson
            .addCase(updateLesson.pending, function (state) {
            state.error = undefined;
            state.isInfoLoading = true;
        })
            .addCase(updateLesson.fulfilled, function (state, action) {
            state.isInfoLoading = false;
            state.lessonInfo = action.payload;
        })
            .addCase(updateLesson.rejected, function (state, action) {
            state.isInfoLoading = false;
            state.error = action.payload;
        })
            // ------------------------------------------ Steps ------------------------------------------
            // fetchLessonSteps
            .addCase(fetchLessonSteps.pending, function (state) {
            state.error = undefined;
            state.isStepsLoading = true;
        })
            .addCase(fetchLessonSteps.fulfilled, function (state, action) {
            state.isStepsLoading = false;
            state.lessonSteps = action.payload;
        })
            .addCase(fetchLessonSteps.rejected, function (state, action) {
            state.isStepsLoading = false;
            state.error = action.payload;
        })
            // createLessonStep
            .addCase(createLessonStep.pending, function (state) {
            state.error = undefined;
            state.isStepsLoading = true;
        })
            .addCase(createLessonStep.fulfilled, function (state) {
            state.isStepsLoading = false;
            // state.lessonSteps = action.payload;
        })
            .addCase(createLessonStep.rejected, function (state, action) {
            state.isStepsLoading = false;
            state.error = action.payload;
        })
            // updateLessonStep
            .addCase(updateLessonStep.pending, function (state) {
            state.error = undefined;
            state.isStepsLoading = true;
        })
            .addCase(updateLessonStep.fulfilled, function (state) {
            state.isStepsLoading = false;
            // state.lessonSteps = action.payload;
        })
            .addCase(updateLessonStep.rejected, function (state, action) {
            state.isStepsLoading = false;
            state.error = action.payload;
        })
            // ------------------------------------------ Tests ------------------------------------------
            // fetchCourseTests
            .addCase(fetchLessonTests.pending, function (state) {
            state.error = undefined;
            state.isTestsLoading = true;
        })
            .addCase(fetchLessonTests.fulfilled, function (state, action) {
            state.isTestsLoading = false;
            state.lessonTests = action.payload;
        })
            .addCase(fetchLessonTests.rejected, function (state, action) {
            state.isTestsLoading = false;
            state.error = action.payload;
        })
            // ------------------------------------------ Tasks ------------------------------------------
            // fetchLessonTasks
            .addCase(fetchLessonTasks.pending, function (state) {
            state.error = undefined;
            state.isTasksLoading = true;
        })
            .addCase(fetchLessonTasks.fulfilled, function (state, action) {
            state.isTasksLoading = false;
            state.lessonTasks = action.payload;
        })
            .addCase(fetchLessonTasks.rejected, function (state, action) {
            state.isTasksLoading = false;
            state.error = action.payload;
        })
            // ------------------------------------------ Glossary ------------------------------------------
            // fetchLessonGlossary
            .addCase(fetchLessonGlossary.pending, function (state) {
            state.error = undefined;
            state.isGlossaryLoading = true;
        })
            .addCase(fetchLessonGlossary.fulfilled, function (state, action) {
            state.isGlossaryLoading = false;
            state.lessonGlossary = action.payload;
        })
            .addCase(fetchLessonGlossary.rejected, function (state, action) {
            state.isGlossaryLoading = false;
            state.error = action.payload;
        });
    },
});
export var lessonEditActions = lessonEditSlice.actions;
export var lessonEditReducer = lessonEditSlice.reducer;
