import { createSlice } from '@reduxjs/toolkit';
import { fetchSignatoryList } from './services/fetchSignatoryList';
var initialState = {
    isLoading: false,
    signatoryList: [],
    error: null,
};
export var signatoryStore = createSlice({
    name: 'glossary',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(fetchSignatoryList.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchSignatoryList.fulfilled, function (state, action) {
            state.isLoading = false;
            state.signatoryList = action.payload;
        })
            .addCase(fetchSignatoryList.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var signatoryActions = signatoryStore.actions;
export var signatoryReducer = signatoryStore.reducer;
