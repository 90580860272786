import { createSlice } from '@reduxjs/toolkit';
import { getCourseStatistic } from '../services/getCourseStatistic';
var initialState = {
    isLoading: false,
    error: false,
    data: null,
};
export var courseStatistcSlice = createSlice({
    name: 'courseListStatistic',
    initialState: initialState,
    reducers: {
        setData: function (state, action) {
            state.data = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getCourseStatistic.pending, function (state) {
            state.isLoading = true;
            state.error = false;
        })
            .addCase(getCourseStatistic.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = false;
            state.data = action.payload;
        })
            .addCase(getCourseStatistic.rejected, function (state) {
            state.isLoading = false;
            state.error = true;
        });
    },
});
export var courseStatistcActions = courseStatistcSlice.actions;
export var courseStatistcReducer = courseStatistcSlice.reducer;
