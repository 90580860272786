var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { classNames } from 'shared/lib/classNames';
import cls from './Select.module.scss';
function SelectComponent(props) {
    var className = props.className, name = props.name, registerOptions = props.registerOptions, defaultValue = props.defaultValue, isMulti = props.isMulti, options = props.options;
    var control = useFormContext().control;
    var setValue = useFormContext().setValue;
    return (_jsx(Controller, { name: name, control: control, defaultValue: defaultValue, rules: registerOptions, render: function (_a) {
            var _b, _c;
            var field = _a.field, fieldState = _a.fieldState, formState = _a.formState;
            return (_jsxs("div", { className: classNames(cls.SelectComponent, {}, [className]), children: [_jsx(Select, __assign({}, props, field, { value: options.find(function (c) { var _a; return c.value === ((_a = field.value) === null || _a === void 0 ? void 0 : _a.value); }), 
                        // @ts-ignore
                        onChange: function (val) { return setValue(name, val.value); }, isMulti: isMulti, className: cls.Select, styles: {
                            control: function (baseStyles) { return (__assign(__assign({}, baseStyles), { padding: '0', fontSize: '16px', borderRadius: '0', boxShadow: 'none', border: 'none', minHeight: 'none', backgroundColor: '#F7F9FB', cursor: 'pointer' })); },
                            indicatorSeparator: function (baseStyles) { return (__assign(__assign({}, baseStyles), { display: 'none' })); },
                            dropdownIndicator: function (baseStyles) { return (__assign(__assign({}, baseStyles), { color: '#18203B', padding: '0' })); },
                            input: function (baseStyles) { return (__assign(__assign({}, baseStyles), { padding: '0', margin: '0' })); },
                            menu: function (baseStyles) { return (__assign(__assign({}, baseStyles), { left: '50%', top: 'calc(100% + 20px)', width: 'calc(100% + 20px)', borderRadius: '12px', border: '1px solid #9C9EAC', transform: 'translate(-50%, 0)', boxShadow: '0 20px 50px rgba(0,0,0,.25)', overflow: 'hidden', backgroundColor: '#F7F9FB' })); },
                            menuList: function (baseStyles) { return (__assign(__assign({}, baseStyles), { paddingTop: '0', paddingBottom: '0' })); },
                        } })), fieldState.error
                        && (_jsx("div", { className: cls.errorMessage, children: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? (_c = fieldState.error) === null || _c === void 0 ? void 0 : _c.message : 'Выберите значение' }))] }));
        } }));
}
export { SelectComponent as Select };
