var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quote-props */
import { useEffect, useRef, useState } from 'react';
import { classNames } from 'shared/lib/classNames';
import { ButtonSize } from 'shared/ui/Button/Button';
import { Button, ButtonTheme } from 'shared/ui/Button';
import { Controller, useFormContext } from 'react-hook-form';
import Close from 'shared/assets/icons/close.svg';
import FileItem from 'shared/assets/icons/fileitem.svg';
import { useDispatch, useSelector } from 'react-redux';
import { courseActions } from 'entities/Course/model/slice/courseSlice';
import { getActiveCourse } from 'entities/Course';
import cls from './FileLoader.module.scss';
function getFileSize(bytes) {
    if (!+bytes)
        return '0 Bytes';
    var k = 1024;
    var sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(2)), " ").concat(sizes[i]);
}
function Tip(_a) {
    var allowedFormats = _a.allowedFormats, maxFileSizeMb = _a.maxFileSizeMb;
    var tip = '';
    if (!!allowedFormats && allowedFormats.length) {
        tip = allowedFormats.map(function (v, i) { return (i === allowedFormats.length - 1 ? " \u0438\u043B\u0438 ".concat(v.toLocaleUpperCase()) : " ".concat(v.toLocaleUpperCase(), ","))
            .replace('.', ''); })
            .join('');
        var commaLast = tip.lastIndexOf(',');
        tip = tip.slice(0, commaLast) + tip.slice(commaLast + 1);
    }
    if (typeof maxFileSizeMb !== 'undefined') {
        if (!!allowedFormats && allowedFormats.length) {
            tip += ',';
        }
        tip += " \u0440\u0430\u0437\u043C\u0435\u0440 \u0444\u0430\u0439\u043B\u0430 \u043D\u0435 \u0431\u043E\u043B\u044C\u0448\u0435 ".concat(maxFileSizeMb, "\u041C\u0431");
    }
    return (_jsx("p", { className: cls.Tip, children: tip }));
}
function FileItemPreview(_a) {
    var file = _a.file;
    return (_jsx("div", { className: cls.ItemPreview, children: _jsx(FileItem, {}) }));
}
export function FileLoader(props) {
    var name = props.name, defaultValue = props.defaultValue, registerOptions = props.registerOptions, className = props.className, maxFileSizeMb = props.maxFileSizeMb, allowedFormats = props.allowedFormats, title = props.title, listTitle = props.listTitle, fileCount = props.fileCount, _a = props.preloadedImageFromServer, preloadedImageFromServer = _a === void 0 ? { name: null, size: 0 } : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b;
    var _c = useFormContext(), control = _c.control, setValue = _c.setValue, setError = _c.setError, clearErrors = _c.clearErrors;
    var dropRef = useRef(null);
    var fileInputTagRef = useRef(null);
    var _d = useState({}), files = _d[0], setFiles = _d[1];
    var activeCourse = useSelector(getActiveCourse);
    var dispatch = useDispatch();
    var fileListToRecord = function (list) {
        var array = {};
        var hasError = false;
        for (var i = 0; i < list.length; i++) {
            var id = String(Math.random() * 999999);
            var file = list.item(i);
            if (typeof maxFileSizeMb !== 'undefined' && file.size > maxFileSizeMb * 1024 * 1024) {
                setError(name, {
                    message: 'Превышен максимальный размер файла',
                });
                hasError = true;
            }
            array[id] = list.item(i);
        }
        if (!hasError) {
            clearErrors(name);
            return array;
        }
        return {};
    };
    var setFilesToForm = function (files) {
        var file = Object.entries(files).map(function (_a) {
            var _ = _a[0], v = _a[1];
            return v;
        })[0];
        setValue(name, file);
        fileInputTagRef.current.value = '';
    };
    useEffect(function () {
        if (!defaultValue) {
            setFiles({});
            setFilesToForm({});
        }
        else {
            setFilesToForm({ file: defaultValue });
        }
    }, [defaultValue]);
    var onInputChange = function (next, event) {
        setFiles(function () {
            var _a;
            var arr = __assign({}, fileListToRecord((_a = event.target.files) !== null && _a !== void 0 ? _a : new FileList()));
            if (typeof fileCount !== 'undefined' && fileCount < Object.keys(arr).length) {
                return {};
            }
            setFilesToForm(arr);
            return arr;
        });
        // next(event);
    };
    var onButtonClick = function () {
        var _a;
        if (dropRef.current) {
            (_a = dropRef.current.querySelector('input')) === null || _a === void 0 ? void 0 : _a.click();
        }
    };
    var onFileDelete = function (id) {
        setFiles({});
        var file = undefined;
        setValue(name, file);
    };
    return (_jsxs("div", { ref: dropRef, className: classNames(cls.FileLoader, {}, [className]), children: [_jsxs("div", { className: cls.Loader, children: [_jsx("p", { className: cls.Title, children: title }), _jsx(Controller, { name: name, control: control, defaultValue: defaultValue, rules: registerOptions, disabled: disabled, render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsxs(_Fragment, { children: [_jsx("input", __assign({}, field, { type: "file", ref: fileInputTagRef, value: "", style: { display: 'none' }, onChange: function (event) { return onInputChange(field.onChange, event); }, accept: allowedFormats === null || allowedFormats === void 0 ? void 0 : allowedFormats.map(function (v) { return (v.includes('.') ? v : ".".concat(v)); }).join(',') })), _jsx(Button, { theme: ButtonTheme.FILE_LOADER, className: cls.Button, onClick: onButtonClick, size: ButtonSize.L, children: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0444\u0430\u0439\u043B" }), _jsx(Tip, { maxFileSizeMb: maxFileSizeMb, allowedFormats: allowedFormats }), fieldState.error && _jsx("p", { className: cls.Error, children: fieldState.error.message })] }));
                        } })] }), _jsxs("div", { className: cls.List, children: [!!Object.keys(files).length
                        && (_jsx("div", { className: cls.ListTitle, children: listTitle && _jsx("p", { children: listTitle }) })), 
                    // eslint-disable-next-line no-nested-ternary
                    Object.keys(files).length ? (Object.entries(files).map(function (_a) {
                        var id = _a[0], file = _a[1];
                        return (_jsxs("div", { className: cls.Item, children: [_jsxs("div", { className: cls.ItemLeft, children: [_jsx(FileItemPreview, { file: file }), _jsx("p", { className: cls.ItemName, children: file.name })] }), _jsx("div", { className: cls.ItemRight, children: _jsx("p", { className: cls.ItemSize, children: getFileSize(file.size) }) }), _jsx("div", { className: cls.ItemDelete, onClick: function () { return onFileDelete(id); }, children: _jsx(Close, {}) })] }, id));
                    })) : (preloadedImageFromServer.name === null || preloadedImageFromServer.name === undefined ? null
                        : (_jsxs("div", { className: cls.Item, children: [_jsxs("div", { className: cls.ItemLeft, children: [_jsx(FileItemPreview, {}), _jsx("p", { className: cls.ItemName, children: preloadedImageFromServer ? preloadedImageFromServer.name : 'loading...' })] }), _jsx("div", { className: cls.ItemRight, children: _jsx("p", { className: cls.ItemSize, children: (preloadedImageFromServer === null || preloadedImageFromServer === void 0 ? void 0 : preloadedImageFromServer.size) ? getFileSize(preloadedImageFromServer.size) : 'loadingSize...' }) }), _jsx("div", { className: cls.ItemDelete, onClick: function () {
                                        dispatch(courseActions.setActiveCourse(__assign(__assign({}, activeCourse), { image: null })));
                                        setFiles({});
                                        var file = undefined;
                                        setValue(name, file);
                                    }, children: _jsx(Close, {}) })] })))] })] }));
}
export var FilesToBase64 = function (files) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, Promise.all(files.map(function (file) { return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () { return resolve(reader.result); };
                reader.onerror = reject;
            }); }))];
    });
}); };
