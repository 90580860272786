import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'shared/lib/classNames/classNames';
import { Breadcrumbs } from 'shared/ui/Breadcrumbs';
import { Menu } from 'shared/ui/Menu';
import { Portal } from 'shared/ui/Portal';
import { Modal, ModalTheme } from 'shared/ui/Modal/Modal';
import { getSupportFormStatus } from 'entities/Support';
import { supportActions } from 'entities/Support/model/slice/supportSlice';
import { FeedbackForm } from 'features/FeedbackForm';
import { ChatWidget, getChatIsModalOpen } from 'entities/Chat';
import { chatActions } from 'entities/Chat/model/slice/chatSlice';
import cls from './Header.module.scss';
export var Header = function (props) {
    var className = props.className;
    var dispatch = useDispatch();
    var feedbackFormStatus = useSelector(getSupportFormStatus);
    var chatModalStatus = useSelector(getChatIsModalOpen);
    return (_jsxs("header", { className: classNames(cls.Header, {}, [className]), children: [_jsx(Breadcrumbs, {}), _jsx(Menu, {}), _jsx(Portal, { children: _jsx(Modal, { isOpen: feedbackFormStatus, onClose: function () { return dispatch(supportActions.toggleSupportForm()); }, children: _jsx(FeedbackForm, { onCloseFormAfterSend: function () { return dispatch(supportActions.toggleSupportForm()); } }) }) }), _jsx(Portal, { children: _jsx(Modal, { theme: ModalTheme.ON_SIDE, isOpen: chatModalStatus, onClose: function () { return dispatch(chatActions.toggleChatModal()); }, children: _jsx(ChatWidget, {}) }) })] }));
};
