import { createSlice } from '@reduxjs/toolkit';
import { createVotingQuestion } from 'features/VotingEdit/services/createVotingQuestion';
import { getVotingById } from 'features/VotingEdit/services/getVotingById';
import { getVotingQuestions } from 'features/VotingEdit/services/getVotingQuestions';
import { getVotingAnswersById } from 'features/VotingEdit/services/getVotingAnswersById';
import { updateVoting } from 'features/VotingEdit/services/updateVoting';
var initialState = {
    isLoading: false,
    isUserTestLoading: false,
    isQuestionLoading: false,
    isNextStepLoading: false,
    isQuestionListLoading: false,
    isQuestionUpdateLoading: false,
    isAnswersListLoading: false,
    isCreateQuestion: false,
    alphabetFilter: null,
    dateFilter: null,
    testsList: [],
    testsForUser: [],
    activeTest: {},
    questionsList: [],
    activeQuestion: {},
    activeAnswersList: [],
    answerRequestType: 0,
};
export var votingSliceEdit = createSlice({
    name: 'votingEdit',
    initialState: initialState,
    reducers: {
        setActiveQuestion: function (state, action) {
            state.activeQuestion = state.testsList.filter(function (question) { return (question === null || question === void 0 ? void 0 : question.id) === action.payload; })[0];
        },
        clearActiveQuestion: function (state) {
            state.activeQuestion = {};
        },
        setActiveAnswer: function (state, action) {
            state.activeAnswersList.push(action.payload);
        },
        updateActiveAnswer: function (state, action) {
            var answerIndex = Number(action.payload.name.slice(-1));
            var keyName = action.payload.name.slice(0, -1);
            // @ts-ignore
            state.activeAnswersList[answerIndex][keyName] = action.payload.value;
        },
        deleteAnswer: function (state, action) {
            if (!state.activeAnswersList.some(function (item) { return item.id === action.payload; }))
                state.activeAnswersList = state.activeAnswersList.filter(function (_, index) { return index !== action.payload; });
            else
                state.activeAnswersList = state.activeAnswersList.filter(function (answer) { return (answer === null || answer === void 0 ? void 0 : answer.id) !== action.payload; });
        },
        clearActiveAnswerList: function (state) {
            state.activeAnswersList = [];
        },
        setIsCreateQuestion: function (state, action) {
            state.isCreateQuestion = action.payload;
        },
        /**
         * @description Удаляет вопрос
         * @param state
         * @param action
         */
        deleteQuestion: function (state, action) {
            state.testsList = state.testsList.filter(function (question) { return question.id !== action.payload; });
        },
        setActiveTest: function (state, action) {
            state.activeTest = action.payload;
        },
        setVotingQuestions: function (state, action) {
            state.testsList = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(createVotingQuestion.fulfilled, function (state, action) {
            state.isQuestionListLoading = false;
            state.testsList.push(action.payload.questionnaire_question);
        }).addCase(createVotingQuestion.pending, function (state) {
            state.isQuestionListLoading = true;
            state.error = undefined;
        }).addCase(getVotingById.fulfilled, function (state, action) {
            state.isQuestionListLoading = false;
            state.activeTest = action.payload;
        }).addCase(getVotingById.pending, function (state) {
            state.isQuestionListLoading = true;
            state.error = undefined;
        })
            .addCase(getVotingQuestions.fulfilled, function (state, action) {
            state.isQuestionListLoading = false;
            if (action.payload instanceof Array && action.payload.length)
                state.testsList = action.payload;
        })
            .addCase(getVotingQuestions.pending, function (state) {
            state.isQuestionListLoading = true;
            state.error = undefined;
        })
            .addCase(getVotingAnswersById.fulfilled, function (state, action) {
            state.isQuestionListLoading = false;
            state.activeAnswersList = action.payload;
        })
            .addCase(updateVoting.pending, function (state) {
            // state.isQuestionListLoading = true;
            state.error = undefined;
        });
    },
});
export var votingEditActions = votingSliceEdit.actions;
export var votingEditReducer = votingSliceEdit.reducer;
