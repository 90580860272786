import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import { Error } from 'features/Error';
import cls from './PageError.module.scss';
export var PageError = function (_a) {
    var className = _a.className;
    var reloadPage = function () {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };
    return (_jsx("div", { className: classNames(cls.PageError, {}, [className]), children: _jsx(Error, { type: "5xx" }) }));
};
