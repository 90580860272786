import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from 'react-redux';
import { createReduxStore } from 'app/providers/StoreProvider/config/store';
export var StoreProvider = function (props) {
    var children = props.children, initialState = props.initialState;
    var navigate = function () {
        alert('DO NOT USE navigate INSIDE API!!!!');
    }; // useNavigate()
    var store = createReduxStore(initialState, navigate);
    return (_jsx(Provider, { store: store, children: children }));
};
