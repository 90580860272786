import { createSlice } from '@reduxjs/toolkit';
import { USER_ACCESS_TOKEN_KEY } from 'shared/const/localStorage';
import { getUserData } from 'entities/User/model/services/getUserData';
import { getUserList } from 'entities/User/model/services/getUserList';
import { getUsersInGroup } from 'entities/User/model/services/getUsersInGroup';
import { onUserAccess } from 'shared/config/helpers/getUserAccess';
import { createUser } from 'entities/User/model/services/createUser';
import { fetchUserById } from 'entities/User/model/services/fetchUserById';
import { updateUser } from 'entities/User/model/services/updateUser';
import { computeUserAchievement } from '../services/computeUserAchievements';
var initialState = {
    isLoading: true,
    isUserListLoading: false,
    isAddUserLoading: false,
    isActiveUserLoading: false,
    userModalStatus: false,
    userRoles: [],
    userAchievements: [],
};
export var userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setAuthData: function (state, action) {
            state.authData = action.payload;
        },
        initAuthData: function (state) {
            var user = localStorage.getItem(USER_ACCESS_TOKEN_KEY);
            if (user) {
                var roles_1 = [];
                state.authData = JSON.parse(user);
                JSON.parse(user).user_roles.map(function (role) { return (roles_1.push(role.name)); });
                state.userRoles = roles_1;
                state.userAccess = onUserAccess(roles_1);
            }
            state.isLoading = false;
        },
        logout: function (state) {
            state.authData = undefined;
            localStorage.removeItem(USER_ACCESS_TOKEN_KEY);
        },
        changeUserModalStatus: function (state) {
            state.userModalStatus = !state.userModalStatus;
        },
        setUserAchievements: function (state, action) {
            // state.userModalStatus = !state.userModalStatus;
        },
    },
    extraReducers: function (builder) {
        builder
            // getUserData
            .addCase(getUserData.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getUserData.fulfilled, function (state, action) {
            state.isLoading = false;
            state.authData = action.payload;
        })
            .addCase(getUserData.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getUserList
            .addCase(getUserList.pending, function (state) {
            state.error = undefined;
            state.isUserListLoading = true;
        })
            .addCase(getUserList.fulfilled, function (state, action) {
            state.isUserListLoading = false;
            state.userList = action.payload;
        })
            .addCase(getUserList.rejected, function (state, action) {
            state.isUserListLoading = false;
            state.error = action.payload;
        })
            // getUsersInGroup
            .addCase(getUsersInGroup.pending, function (state) {
            state.error = undefined;
            state.isUserListLoading = true;
        })
            .addCase(getUsersInGroup.fulfilled, function (state, action) {
            state.isUserListLoading = false;
            state.userList = action.payload;
        })
            .addCase(getUsersInGroup.rejected, function (state, action) {
            state.isUserListLoading = false;
            state.error = action.payload;
        })
            // createUser
            .addCase(createUser.pending, function (state) {
            state.error = undefined;
            state.isAddUserLoading = true;
        })
            .addCase(createUser.fulfilled, function (state) {
            state.isAddUserLoading = false;
        })
            .addCase(createUser.rejected, function (state, action) {
            state.isAddUserLoading = false;
            state.error = action.payload;
        })
            // fetchUserById
            .addCase(fetchUserById.pending, function (state) {
            state.error = undefined;
            state.isActiveUserLoading = true;
        })
            .addCase(fetchUserById.fulfilled, function (state, action) {
            state.isActiveUserLoading = false;
            state.activeUser = action.payload;
        })
            .addCase(fetchUserById.rejected, function (state, action) {
            state.isActiveUserLoading = false;
            state.error = action.payload;
        })
            // updateUser
            .addCase(updateUser.pending, function (state) {
            state.error = undefined;
            state.isActiveUserLoading = true;
        })
            .addCase(updateUser.fulfilled, function (state) {
            state.isActiveUserLoading = false;
            // state.activeUser = action.payload;
        })
            .addCase(updateUser.rejected, function (state, action) {
            state.isActiveUserLoading = false;
            state.error = action.payload;
        })
            // compute user achievements
            .addCase(computeUserAchievement.pending, function (state) {
            state.error = undefined;
        })
            .addCase(computeUserAchievement.fulfilled, function (state, action) {
            state.isActiveUserLoading = false;
            state.userAchievements = action.payload;
        })
            .addCase(computeUserAchievement.rejected, function (state, action) {
            state.error = action.payload;
        });
    },
});
export var userActions = userSlice.actions;
export var userReducer = userSlice.reducer;
