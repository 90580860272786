// Тип пользователя
export var DUserType = {
    1: 'Преподаватель',
    2: 'Эксперт',
    3: 'Ученик',
    4: 'Участник',
};
// Статус администрирования
export var DBackendStatus = {
    0: 'Пользователь',
    2: 'Администратор',
    3: 'Супер администратор',
};
// Статус пользователя
export var DStatus = {
    1: 'Активный',
    2: 'Не активный',
};
// Допуски
export var DAccess = {
    canEditAdmin: 'Редактирование админки',
    simpleRights: 'Обычный пользователь платформы',
};
