import { createSlice } from '@reduxjs/toolkit';
import { getGroupsList } from '../services/getGroupsList';
var initialState = {
    isLoading: true,
    groupList: [],
};
export var groupSlice = createSlice({
    name: 'group',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getGroupsList.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getGroupsList.fulfilled, function (state, action) {
            state.isLoading = false;
            state.groupList = action.payload;
        })
            .addCase(getGroupsList.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var groupActions = groupSlice.actions;
export var groupReducer = groupSlice.reducer;
