import { createSlice } from '@reduxjs/toolkit';
import { fetchCourseTests } from 'features/CourseEdit/services/fetchCourseTests';
import { updateTest } from '../services/updateTest';
import { createTest } from '../services/createTest';
import { deleteTest } from '../services/deleteTest';
import { getTestById } from '../services/getTestById';
import { setTestAnswer } from '../services/setTestAnswer';
import { fetchUserTests } from '../services/fetchUserTest';
import { fetchAdminTests } from '../services/fetchAdminTest';
import { getTestDataById } from '../services/getTestDataById';
import { updateTestAnswer } from '../services/updateTestAnswer';
import { getTestQuestions } from '../services/getTestQuestions';
import { deleteTestQuestion } from '../services/deleteTestQuestion';
import { createTestQuestion } from '../services/createTestQuestion';
import { getTestAnswersById } from '../services/getTestAnswersById';
import { updateTestQuestion } from '../services/updateTestQuestion';
import { getNextTestQuestion } from '../services/getNextTestQuestion';
import { updateBindTestToLesson } from '../services/updateBindTestToLesson';
var initialState = {
    isLoading: false,
    isUserTestLoading: false,
    isQuestionLoading: false,
    isNextStepLoading: false,
    isQuestionListLoading: false,
    isQuestionUpdateLoading: false,
    isAnswersListLoading: false,
    isCreateQuestion: false,
    isCourseTestsLoading: false,
    alphabetFilter: null,
    dateFilter: null,
    testsList: [],
    testsForUser: [],
    activeTest: {},
    questionsList: [],
    activeQuestion: {},
    activeAnswersList: [],
    answerRequestType: 0,
    courseTests: [],
    search: '',
    currentType: 0,
    searchCourse: '',
    testResults: null,
};
export var testSlice = createSlice({
    name: 'test',
    initialState: initialState,
    reducers: {
        setAlphabetFilter: function (state, action) {
            state.alphabetFilter = action.payload;
        },
        setDateFilter: function (state, action) {
            state.dateFilter = action.payload;
        },
        setActiveQuestion: function (state, action) {
            state.activeQuestion = state.questionsList.filter(function (question) { return (question === null || question === void 0 ? void 0 : question.id) === action.payload; })[0];
        },
        clearActiveQuestion: function (state) {
            state.activeQuestion = {};
        },
        setActiveAnswer: function (state, action) {
            state.activeAnswersList.push(action.payload);
        },
        updateActiveAnswer: function (state, action) {
            var answerIndex = Number(action.payload.name.slice(-1));
            var keyName = action.payload.name.slice(0, -1);
            // @ts-ignore
            state.activeAnswersList[answerIndex][keyName] = action.payload.value;
        },
        deleteAnswer: function (state, action) {
            if (!state.activeAnswersList.some(function (item) { return item.id === action.payload; }))
                state.activeAnswersList = state.activeAnswersList.filter(function (_, index) { return index !== action.payload; });
            else
                state.activeAnswersList = state.activeAnswersList.filter(function (answer) { return (answer === null || answer === void 0 ? void 0 : answer.id) !== action.payload; });
        },
        clearActiveAnswerList: function (state) {
            state.activeAnswersList = [];
        },
        setIsCreateQuestion: function (state, action) {
            state.isCreateQuestion = action.payload;
        },
        changeLessonId: function (state, action) {
            state.bindingInfo = action.payload;
        },
        setSearch: function (state, action) {
            state.search = action.payload;
        },
        setCurrentType: function (state, action) {
            state.currentType = action.payload;
        },
        setSearchCourse: function (state, action) {
            state.searchCourse = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            // fetchAdminTests
            .addCase(fetchAdminTests.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchAdminTests.fulfilled, function (state, action) {
            state.isLoading = false;
            state.testsList = action.payload;
        })
            .addCase(fetchAdminTests.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // fetchUserTests
            .addCase(fetchUserTests.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(fetchUserTests.fulfilled, function (state, action) {
            state.isLoading = false;
            state.testsForUser = action.payload;
        })
            .addCase(fetchUserTests.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getNextTestQuestion
            .addCase(getNextTestQuestion.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getNextTestQuestion.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeTest = action.payload;
        })
            .addCase(getNextTestQuestion.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // setTestAnswer
            .addCase(setTestAnswer.pending, function (state) {
            state.error = undefined;
            state.isNextStepLoading = true;
        })
            .addCase(setTestAnswer.fulfilled, function (state) {
            state.isNextStepLoading = false;
            // state.activeTest = action.payload;
        })
            .addCase(setTestAnswer.rejected, function (state, action) {
            state.isNextStepLoading = false;
            state.error = action.payload;
        })
            // getTestById
            .addCase(getTestById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getTestById.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeTest = action.payload;
        })
            .addCase(getTestById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getTestQuestions
            .addCase(getTestQuestions.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getTestQuestions.fulfilled, function (state, action) {
            state.isLoading = false;
            state.questionsList = action.payload;
        })
            .addCase(getTestQuestions.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getTestDataById
            .addCase(getTestDataById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getTestDataById.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeTest = action.payload.test;
        })
            .addCase(getTestDataById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // createTest
            .addCase(createTest.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(createTest.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeTest = action.payload.test;
        })
            .addCase(createTest.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // updateTest
            .addCase(updateTest.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(updateTest.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeTest = action.payload;
        })
            .addCase(updateTest.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // deleteTest
            .addCase(deleteTest.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(deleteTest.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(deleteTest.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // createTestQuestion
            .addCase(createTestQuestion.pending, function (state) {
            state.error = undefined;
            state.isQuestionUpdateLoading = true;
        })
            .addCase(createTestQuestion.fulfilled, function (state, action) {
            state.isQuestionUpdateLoading = false;
            state.isCreateQuestion = false; // Нужно перевести в false, что бы при создании нового вопроса форма не сбрасывалась.
            state.activeQuestion = action.payload;
            state.questionsList.push(action.payload);
        })
            .addCase(createTestQuestion.rejected, function (state, action) {
            state.isQuestionUpdateLoading = false;
            state.error = action.payload;
        })
            // updateTestQuestion
            .addCase(updateTestQuestion.pending, function (state) {
            state.error = undefined;
            state.isQuestionUpdateLoading = true;
        })
            .addCase(updateTestQuestion.fulfilled, function (state, action) {
            var editIndex;
            state.isQuestionUpdateLoading = false;
            state.activeQuestion = action.payload;
            state.questionsList.map(function (question, index) {
                if (question.id === action.payload.id) {
                    editIndex = index;
                }
                return null;
            });
            if (editIndex)
                state.questionsList[editIndex] = action.payload;
        })
            .addCase(updateTestQuestion.rejected, function (state, action) {
            state.isQuestionUpdateLoading = false;
            state.error = action.payload;
        })
            // deleteTestQuestion
            .addCase(deleteTestQuestion.pending, function (state) {
            state.error = undefined;
            state.isQuestionListLoading = true;
        })
            .addCase(deleteTestQuestion.fulfilled, function (state, action) {
            state.isQuestionListLoading = false;
            state.questionsList = state.questionsList.filter(function (question) { return (question === null || question === void 0 ? void 0 : question.id) !== Number(action.payload.question_id); });
        })
            .addCase(deleteTestQuestion.rejected, function (state, action) {
            state.isQuestionListLoading = false;
            state.error = action.payload;
        })
            // getTestAnswersById
            .addCase(getTestAnswersById.pending, function (state) {
            state.error = undefined;
            // state.isQuestionListLoading = true;
        })
            .addCase(getTestAnswersById.fulfilled, function (state, action) {
            state.isQuestionListLoading = false;
            state.activeAnswersList = action.payload;
            state.answerRequestType = action.payload.length;
        })
            .addCase(getTestAnswersById.rejected, function (state, action) {
            state.isQuestionListLoading = false;
            state.error = action.payload;
        })
            // updateTestAnswer
            .addCase(updateTestAnswer.pending, function (state) {
            state.error = undefined;
            state.isAnswersListLoading = true;
        })
            .addCase(updateTestAnswer.fulfilled, function (state, action) {
            state.isAnswersListLoading = false;
            state.activeAnswersList = action.payload;
        })
            .addCase(updateTestAnswer.rejected, function (state, action) {
            state.isAnswersListLoading = false;
            state.error = action.payload;
        })
            .addCase(fetchCourseTests.pending, function (state) {
            state.isCourseTestsLoading = true;
        })
            .addCase(fetchCourseTests.fulfilled, function (state, action) {
            state.isCourseTestsLoading = false;
            state.courseTests = Object.values(action.payload);
        })
            .addCase(updateBindTestToLesson.rejected, function () {
            // ...
        })
            .addCase(updateBindTestToLesson.pending, function () {
            // ...
        })
            .addCase(updateBindTestToLesson.fulfilled, function () {
            // ...
        });
    },
});
export var testActions = testSlice.actions;
export var testReducer = testSlice.reducer;
