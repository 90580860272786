import { createSlice } from '@reduxjs/toolkit';
import { updateArticle } from 'entities/Base/model/services/updateArticle';
import { createArticle } from 'entities/Base/model/services/createArticle';
import { sendNewSectionName } from 'entities/Base/model/services/sendNewSectionName';
import { getKnowledgeBase } from 'entities/Base/model/services/getKnowledgeBase';
import { getGlossary } from 'entities/Base/model/services/getGlossary';
import { getBaseContent } from 'entities/Base/model/services/getBaseContent';
import { deleteArticle } from 'entities/Base/model/services/deleteArticle';
import { hideArticle } from 'entities/Base/model/services/hideArticle';
import { getBaseTags } from 'entities/Base/model/services/getBaseTags';
import { getGlossarySingleItem } from '../services/getGlossarySingleItem';
var initialState = {
    isLoading: false,
    error: undefined,
    base: undefined,
    chapter: 0,
    section: 0,
    glossary: undefined,
    content: undefined,
    type: 'base',
    tags: null,
};
export var baseSlice = createSlice({
    name: 'base',
    initialState: initialState,
    reducers: {
        setChapterAndSection: function (state, action) {
            state.chapter = action.payload.chapter;
            state.section = action.payload.section;
        },
        setContent: function (state, action) {
            if (state.content !== undefined) {
                state.content.name = action.payload.title;
                state.content.content = action.payload.content;
            }
        },
        setType: function (state, action) {
            state.type = action.payload.type;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getKnowledgeBase.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getKnowledgeBase.fulfilled, function (state, action) {
            state.base = action.payload.data;
            state.isLoading = false;
            // // @ts-ignore
            // state.content = {};
            // // @ts-ignore
            // state.content = action.payload.data[0].content;
            // // state.content.content = action.payload.data.content;
        })
            .addCase(getKnowledgeBase.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getGlossary.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getGlossary.fulfilled, function (state, action) {
            state.glossary = action.payload.glossary_items;
            state.isLoading = false;
        })
            .addCase(getGlossary.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getGlossarySingleItem.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getGlossarySingleItem.fulfilled, function (state, action) {
            state.content = action.payload.glossaryItem;
            if (state.content !== undefined) {
                state.content.name = action.payload.glossaryItem.name;
                state.content.content = action.payload.glossaryItem.description;
            }
            state.isLoading = false;
        })
            .addCase(getGlossarySingleItem.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getBaseContent.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getBaseContent.fulfilled, function (state, action) {
            state.content = action.payload.data;
            state.isLoading = false;
        })
            .addCase(getBaseContent.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(sendNewSectionName.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(sendNewSectionName.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(sendNewSectionName.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(createArticle.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(createArticle.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(createArticle.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(updateArticle.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(updateArticle.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(updateArticle.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(deleteArticle.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(deleteArticle.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(deleteArticle.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(hideArticle.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(hideArticle.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(hideArticle.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getBaseTags.pending, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(getBaseTags.fulfilled, function (state, action) {
            var mappedTags = {};
            action.payload.data.forEach(function (tag) {
                mappedTags[tag.id] = tag;
            });
            state.tags = mappedTags;
            state.isLoading = false;
        })
            .addCase(getBaseTags.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var baseActions = baseSlice.actions;
export var baseReducer = baseSlice.reducer;
