import { createSlice } from '@reduxjs/toolkit';
import { getVoting } from 'entities/Voting/model/services/getVoting';
import { getVotingById } from 'entities/Voting/model/services/getVotingById';
import { fetchVotingResults } from 'widgets/VotingResults/api';
var initialState = {
    isLoading: false,
    votingList: [],
    activeVoting: {},
};
export var votingSlice = createSlice({
    name: 'voting',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            // getVoting
            .addCase(getVoting.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getVoting.fulfilled, function (state, action) {
            state.isLoading = false;
            state.votingList = action.payload;
        })
            .addCase(getVoting.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getVoting
            .addCase(getVotingById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getVotingById.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeVoting = action.payload;
        })
            .addCase(getVotingById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(fetchVotingResults.pending, function (state) {
            // state.error = undefined;
            // state.isLoading = true;
        })
            .addCase(fetchVotingResults.fulfilled, function (state, action) {
            // state.isLoading = false;
            state.votingResults = action.payload;
        })
            .addCase(fetchVotingResults.rejected, function (state, action) {
            // state.isLoading = false;
            // state.error = action.payload;
        });
    },
});
export var votingActions = votingSlice.actions;
export var votingReducer = votingSlice.reducer;
