import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, Link } from 'react-router-dom';
import { classNames } from 'shared/lib/classNames/classNames';
import cls from './Breadcrumbs.module.scss';
var pathNamesMap = {
    about: 'О нас',
    tests: 'Тесты',
    lesson: 'Урок',
    // courses: 'Курсы',
    lessons: 'Курсы',
    groups: 'Группы',
    tasks: 'Задания',
    profile: 'Профиль',
    contact: 'Контакты',
    base: 'База знаний',
    glossary: 'Глоссарий',
    team: 'Моя команда',
    calendar: 'Календарь',
    users: 'Пользователи',
    evaluations: 'Мои оценки',
    voting: 'Мои голосования',
    home: 'Стартовая страница',
    track: 'Образовательный трек',
    achievements: 'Мои достижения',
    support: 'Техническая поддержка',
    statistic: 'Статистика обучения',
    courseEdit: 'Редактирование курса',
    lessonEdit: 'Редактирование урока',
    teamEdit: 'Редактирование комманды',
    votingPageEdit: 'Редактирование голосований',
    create: 'Создание',
};
export var Breadcrumbs = function (_a) {
    var className = _a.className;
    var location = useLocation();
    // без filter в pathNames первый элемент пустой и появляется пустое поле в хлебных крошках
    var pathNames = location.pathname.split('/').filter(function (x) { return x; });
    return (_jsx("div", { children: _jsxs("ul", { className: classNames(cls.Breadcrumbs, {}, [className]), children: [_jsx("li", { children: _jsx(Link, { to: "/", children: pathNamesMap.home || 'Home' }) }), pathNames.map(function (name, index) {
                    var routeTo = "/".concat(pathNames
                        .slice(0, index + 1)
                        .join('/'));
                    var isLast = index === pathNames.length - 1;
                    var displayName = pathNamesMap[name] || name;
                    if (isLast) {
                        return _jsxs("li", { children: [" / ", displayName] }, name);
                    }
                    return (_jsx("li", { children: _jsxs(Link, { to: routeTo, children: [" / ", displayName] }) }, name));
                })] }) }));
};
