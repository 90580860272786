export var getCourse = function (state) { return state.course; };
export var getCourseVisibility = function (state) { return state.course.visibility; };
export var getCardView = function (state) { return state.course.isShortView; };
export var getActiveCourse = function (state) { return state.course.activeCourse; };
export var getCourseIsLoading = function (state) { return state.course.isLoading; };
export var getCourseIsSetClubsLoading = function (state) { return state.course.isSetClubsLoading; };
export var getCourseDateFilter = function (state) { return state.course.dateFilter; };
export var getThemeFilter = function (state) { return state.course.themeFilter; };
export var getCourseAlphabetFilter = function (state) { return state.course.alphabetFilter; };
export var setView = function (state) { return state.course; };
export var getLessonIsLoading = function (state) { return state.lesson.isLoading; };
export var getActiveCourseLessons = function (state) { return state.course.activeCourse.lessons; };
export var getActiveCourseTests = function (state) { return state.course.activeCourse.tests; };
export var getCourseCertificatesSelector = function (state) { return state.course.certificates; };
export var getCourseExpertsSelector = function (state) { return state.course.expertList; };
export var getCourseExpertsBindSelector = function (state) { return state.course.expertBindList; };
