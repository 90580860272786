var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import cls from './AppLink.module.scss';
export var AppLinkTheme;
(function (AppLinkTheme) {
    AppLinkTheme["PRIMARY"] = "primary";
    AppLinkTheme["ACCENT"] = "accent";
    AppLinkTheme["INVERTED"] = "inverted";
    AppLinkTheme["NAVIGATION"] = "navigation";
    AppLinkTheme["BUTTON_MODAL_TEXT"] = "buttonModalText";
})(AppLinkTheme || (AppLinkTheme = {}));
export var AppLinkSize;
(function (AppLinkSize) {
    AppLinkSize["XL"] = "xl";
    AppLinkSize["L"] = "l";
    AppLinkSize["M"] = "m";
    AppLinkSize["S"] = "s";
    AppLinkSize["XS"] = "xs";
})(AppLinkSize || (AppLinkSize = {}));
export var AppLink = function (props) {
    var _a, _b;
    var to = props.to, className = props.className, children = props.children, reverse = props.reverse, _c = props.theme, theme = _c === void 0 ? '' : _c, icon = props.icon, _d = props.width, width = _d === void 0 ? 'auto' : _d, _e = props.size, size = _e === void 0 ? '' : _e, target = props.target, otherProps = __rest(props, ["to", "className", "children", "reverse", "theme", "icon", "width", "size", "target"]);
    var $link = useRef(null);
    useEffect(function () {
        $link.current && $link.current.style.setProperty('--custom-width', width);
    }, []);
    var mods = (_a = {},
        _a[cls.reverse] = reverse,
        _a);
    return (_jsxs(Link, __assign({ to: to, className: classNames(cls.AppLink, mods, [className, cls[theme], cls[size]]), ref: $link }, otherProps, { target: target, children: [icon
                && (_jsx("span", { className: classNames(cls.icon, (_b = {}, _b[cls.mr12] = !!icon, _b), []), children: icon })), children && _jsx("span", { className: cls.text, children: children })] })));
};
