import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    activeBurger: false,
};
export var navigationSlice = createSlice({
    name: 'navigation',
    initialState: initialState,
    reducers: {
        burgerStatus: function (state) {
            state.activeBurger = !state.activeBurger;
        },
    },
});
export var navigationActions = navigationSlice.actions;
export var navigationReducer = navigationSlice.reducer;
