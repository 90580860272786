var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { useWindowResolution } from 'shared/lib/hooks/useWindowResolution';
import { useLocation } from 'react-router-dom';
import ArrowRightSvg from '../../../../assets/icons/arrowRight.svg';
import cls from './StatisticTable.module.scss';
function StatisticTable(_a) {
    var columns = _a.columns, children = _a.children, onColumnClick = _a.onColumnClick;
    var initColumnsState = useCallback(function () {
        var result = {};
        for (var _i = 0, _a = Object.entries(columns); _i < _a.length; _i++) {
            var key = _a[_i][0];
            result[key] = false;
        }
        return result;
    }, [columns]);
    var _b = useState(initColumnsState()), columnsState = _b[0], setColumnsState = _b[1];
    var windowResolution = useWindowResolution();
    var _c = useState(false), isEndReached = _c[0], setIsEndReached = _c[1];
    var pathname = useLocation().pathname;
    var currentLocation = pathname.split('/');
    var statisticTableHTMLEl = useRef(null);
    var isOnLessonStatisticPage = currentLocation.includes('statistic') && currentLocation.includes('lesson');
    if (windowResolution.width <= 766) {
        return (_jsx("div", { children: children }));
    }
    var handleScroll = function (e) {
        var div = e.currentTarget;
        if (!isEndReached && div.scrollLeft + div.clientWidth >= div.scrollWidth - 50) {
            setIsEndReached(true);
        }
        else if (isEndReached && div.scrollLeft + div.clientWidth < div.scrollWidth - 50) {
            setIsEndReached(false);
        }
    };
    var scrollOnClick = function () {
        if (statisticTableHTMLEl.current) {
            statisticTableHTMLEl.current.scrollLeft += 300;
        }
    };
    return (_jsxs("div", { ref: statisticTableHTMLEl, className: "".concat(cls.tableContainer), onScroll: handleScroll, children: [_jsx(ArrowRightSvg, { onClick: scrollOnClick, 
                // eslint-disable-next-line max-len
                className: "".concat(cls.arrowRight, " ").concat(isEndReached ? '' : cls.show, " ").concat(isOnLessonStatisticPage ? '' : cls.isOnLessonStatisticPage) }), _jsxs("table", { className: "table ".concat(cls.table), children: [_jsx("thead", { className: cls.thead, children: _jsx("tr", { className: cls.tr, children: Object.entries(columns).map(function (_a) {
                                var key = _a[0], name = _a[1];
                                return (_jsx("td", { className: cls.td, style: { fontWeight: 'bold' }, onClick: function () {
                                        if (typeof onColumnClick === 'undefined')
                                            return;
                                        onColumnClick(key, !columnsState[key]);
                                        setColumnsState(function (p) {
                                            var old = __assign({}, p);
                                            old[key] = !p[key];
                                            return old;
                                        });
                                    }, children: name }, key));
                            }) }) }), children] })] }));
}
export { StatisticTable };
