import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { AppRouter } from 'app/providers/Router';
import { useDispatch } from 'react-redux';
import { userActions } from 'entities/User';
import { getUserData } from 'entities/User/model/services/getUserData';
import { ToastContainer } from 'react-toastify';
import 'app/styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
var App = function () {
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(userActions.initAuthData());
        // if (!authData) {
        // @ts-ignore
        dispatch(getUserData(''));
        // }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, {}), _jsx(AppRouter, {})] }));
};
export default App;
