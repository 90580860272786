var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from 'shared/lib/classNames/classNames';
import Select from 'react-select';
import cls from './SimpleSelect.module.scss';
export var SimpleSelect = function (props) {
    var className = props.className, options = props.options, placeholder = props.placeholder, propsValue = props.propsValue, defaultValue = props.defaultValue, _a = props.isClearable, isClearable = _a === void 0 ? true : _a, onSelectValue = props.onSelectValue;
    var selectedOption = options === null || options === void 0 ? void 0 : options.find(function (option) { return option.value === propsValue; });
    return (_jsx("div", { className: classNames(cls.SimpleSelect, {}, [className]), children: _jsx(Select, { options: options, styles: {
                menu: function (provided) { return (__assign(__assign({}, provided), { width: 'fit-content', minWidth: '30px' })); },
            }, className: className, onChange: function (value) { return (onSelectValue && onSelectValue(value)); }, placeholder: placeholder, value: selectedOption, defaultValue: defaultValue, isClearable: isClearable }) }));
};
