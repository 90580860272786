import { createSlice } from '@reduxjs/toolkit';
import { getLessonById } from '../services/getLessonById';
import { updateLesson } from '../services/updateLesson';
import { getLessonSteps } from '../services/getLessonSteps';
import { getLessonAdminSteps } from '../services/getLessonAdminSteps';
import { fetchTestInLesson } from '../services/fetchTestInLesson';
var initialState = {
    isLoading: false,
    lessonsInCourse: [],
    activeLesson: {},
};
export var lessonSlice = createSlice({
    name: 'lesson',
    initialState: initialState,
    reducers: {
        setLessonsInCourse: function (state, action) {
            state.lessonsInCourse = action.payload;
        },
        updateActiveLesson: function (state, action) {
            state.userLesson = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getLessonById.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getLessonById.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeLesson = action.payload;
        })
            .addCase(getLessonById.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }) // getLessonSteps
            .addCase(getLessonSteps.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getLessonSteps.fulfilled, function (state, action) {
            state.isLoading = false;
            state.userLesson = action.payload;
        })
            .addCase(getLessonSteps.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // getLessonAdminSteps
            .addCase(getLessonAdminSteps.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(getLessonAdminSteps.fulfilled, function (state, action) {
            state.isLoading = false;
            state.activeLesson.managedSteps = action.payload;
        })
            .addCase(getLessonAdminSteps.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            // updateLesson
            .addCase(updateLesson.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(updateLesson.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        })
            .addCase(updateLesson.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = undefined;
            state.activeLesson = action.payload;
        })
            // fetchTestInLesson
            .addCase(fetchTestInLesson.pending, function (state) {
            state.error = undefined;
            // state.isLoading = true;
        })
            .addCase(fetchTestInLesson.fulfilled, function (state, action) {
            // state.isLoading = false;
            state.testsInLesson = action.payload;
        })
            .addCase(fetchTestInLesson.rejected, function (state, action) {
            // state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var lessonActions = lessonSlice.actions;
export var lessonReducer = lessonSlice.reducer;
