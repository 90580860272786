import { createSlice } from '@reduxjs/toolkit';
import { loginByEmail } from '../services/loginByEmail/loginByEmail';
var initialState = {
    // email: '',
    // password: '',
    isLoading: false,
};
export var loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(loginByEmail.pending, function (state) {
            state.error = undefined;
            state.isLoading = true;
        })
            .addCase(loginByEmail.fulfilled, function (state) {
            state.isLoading = false;
        })
            .addCase(loginByEmail.rejected, function (state, action) {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});
export var loginActions = loginSlice.actions;
export var loginReducer = loginSlice.reducer;
