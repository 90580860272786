import { createSlice } from '@reduxjs/toolkit';
import { getLessonStatistic } from '../services/getLessonStatistic';
var initialState = {
    isLoading: false,
    error: false,
    data: null,
    filter: {
        groupFilter: '',
        IDFilter: '',
        userNameFilter: '',
        lessonFilter: '',
        publishedFilter: '',
        currentFilter: '',
        dateFilter: '',
        checkedFilter: '',
    },
};
export var lessonStatisticSlice = createSlice({
    name: 'lessonStatistic',
    initialState: initialState,
    reducers: {
        setData: function (state, action) {
            state.data = action.payload;
        },
        setGroupFilter: function (state, action) {
            state.filter.groupFilter = action.payload;
        },
        setIdFilter: function (state, action) {
            state.filter.IDFilter = action.payload;
        },
        setLessonFilter: function (state, action) {
            state.filter.lessonFilter = action.payload;
        },
        setPublishedFilter: function (state, action) {
            state.filter.publishedFilter = action.payload;
        },
        setCurrentFilter: function (state, action) {
            state.filter.currentFilter = action.payload;
        },
        setDateFilter: function (state, action) {
            state.filter.dateFilter = action.payload;
        },
        setCheckedFilter: function (state, action) {
            state.filter.checkedFilter = action.payload;
        },
        setUserNameFilter: function (state, action) {
            state.filter.userNameFilter = action.payload;
        },
    },
    extraReducers: function (builder) {
        builder
            // Загрузка статистики урока
            .addCase(getLessonStatistic.pending, function (state) {
            state.isLoading = true;
            state.error = false;
        })
            .addCase(getLessonStatistic.fulfilled, function (state, action) {
            state.isLoading = false;
            state.error = false;
            state.data = action.payload;
        })
            .addCase(getLessonStatistic.rejected, function (state) {
            state.isLoading = false;
            state.error = true;
        });
    },
});
export var lessonStatisticActions = lessonStatisticSlice.actions;
export var lessonStatisticReducer = lessonStatisticSlice.reducer;
