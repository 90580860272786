export var getTest = function (state) { return state.test; };
export var getTestsList = function (state) { return state.test.testsList; };
export var getActiveTest = function (state) { return state.test.activeTest; };
export var getTestIsLoading = function (state) { return state.test.isLoading; };
export var getTestsForUser = function (state) { return state.test.testsForUser; };
export var getTestDateFilter = function (state) { return state.test.dateFilter; };
export var getTestBindingInfo = function (state) { return state.test.bindingInfo; };
export var getTestAlphabetFilter = function (state) { return state.test.alphabetFilter; };
export var getTestActiveQuestions = function (state) { return state.test.questionsList; };
export var getTestActiveQuestion = function (state) { return state.test.activeQuestion; };
export var getActiveAnswersList = function (state) { return state.test.activeAnswersList; };
export var getAnswerRequestLength = function (state) { return state.test.answerRequestType; };
export var getTestIsCreateQuestion = function (state) { return state.test.isCreateQuestion; };
export var getTestIsAnswersListLoading = function (state) { return state.test.isAnswersListLoading; };
export var getTestIsQuestionListLoading = function (state) { return state.test.isQuestionListLoading; };
export var getTestIsQuestionUpdateLoading = function (state) { return state.test.isQuestionUpdateLoading; };
export var getIsCourseTestsLoading = function (state) { return state.test.isCourseTestsLoading; };
export var getCourseTests = function (state) { return state.test.courseTests; };
export var getTestSearch = function (state) { return state.test.search; };
export var getTestSearchCourse = function (state) { return state.test.searchCourse; };
export var getCurrentType = function (state) { return state.test.currentType; };
export var getTestError = function (state) { return state.test.error; };
export var getTestResults = function (state) { return state.test.testResults; };
